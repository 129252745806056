import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import PersistAble from '~/models/mixins/PersistAble'
import Organization from '~/models/directories/Organization'

export default class DebtItems extends ChainInheritance(Administration, [
  SoftDeletAble,
  PersistAble
]) {
  static entity = 'debt_items'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortOrder = true

  static fields () {
    return super.fields({
      organization_id: this.attr(),

      id: this.attr(null),
      provider: this.attr(null),
      type: this.attr(null),
      relatedEntityName: this.attr(null),
      relatedEntityId: this.attr(null),
      providerData: this.attr(null),
      count: this.attr(null),
      price: this.attr(null),
      discount: this.attr(null),
      organization: this.belongsTo(Organization, 'organization_id'),
      invoice: this.attr(null)
    })
  }

  static ormTrans = {
    single: 'Consumption',
    multy: 'Consumptions'
  }

  get organizationString () {
    return this?.organization?.name
  }

  get hasInvoice () {
    return this.invoice ? 'Так' : 'Ні'
  }

  get dnTradeOutletCount () {
    return this._.get(this, 'providerData.count', '')
  }

  get dnTradeOutletName () {
    return this._.get(this, 'providerData.name', '')
  }

  get priceString () {
    return this.price ? this.price / 100 : null
  }

  static ormHeaders = [
    { text: 'Partner', value: 'provider', sortable: false, filterable: false },
    { text: 'Type', value: 'type', sortable: false, filterable: false },
    { text: 'Count', value: 'count', sortable: false, filterable: false },
    { text: 'Outlet documents count', value: 'dnTradeOutletCount', sortable: false, filterable: false },
    { text: 'Outlet Name', value: 'dnTradeOutletName', sortable: false, filterable: false },
    { text: 'Price', value: 'priceString', sortable: false, filterable: false },
    { text: 'Organization ', value: 'organizationString', sortable: false, filterable: false },
    { text: 'Has invoice', value: 'hasInvoice', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormActions = [
    {
      name: 'delete'
    }
  ]

  static ormDialogs = {
    delete: 'm-orm-delete-dialog'
  }

  static ormDialogsConfig = {
    delete: {
      title: item => ({ type: this.ormTrans.single, name: item.provider }),
      notification: item => ({ type: this.ormTrans.single, name: item.provider })
    }
  }
}
