import { DateTime } from '~/services/_utils/DateTime'
import { formatPriceFromApi } from '~/services/_utils/Currency'
import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import PersistAble from '~/models/mixins/PersistAble'
import Commission from '~/models/partner/Commission'

export default class CommissionItems extends ChainInheritance(Administration, [
  SoftDeletAble,
  PersistAble
]) {
  static entity = 'commission_items'
  static paginated = true
  static defaultSortOrder = false
  static ormLoadWithRelations = true

  static fields () {
    return super.fields({
      id: this.attr(null),
      service: this.attr(null),
      actAmount: this.attr(null),
      commission: this.belongsTo(Commission),
      commissionAmount: this.attr(null),
      billingStartDate: this.attr(null),
      billingEndDate: this.attr(null),
      contrAgentName: this.attr(null),
      paidAt: this.attr(null)
    })
  }

  static ormTrans = {
    single: 'Commission item',
    multy: 'Commission items'
  }

  get paidDateString () {
    return DateTime.format(this.paidAt)
  }

  get billingEndDateString () {
    return DateTime.format(this.billingEndDate)
  }

  get billingStartDateString () {
    return DateTime.format(this.billingStartDate)
  }

  get commissionAmountString () {
    return formatPriceFromApi(this.commissionAmount)
  }

  get actAmountString () {
    return formatPriceFromApi(this.actAmount)
  }

  static ormHeaders = [
    { text: 'Start date', value: 'billingStartDateString', sortable: false, filterable: false },
    { text: 'End date', value: 'billingEndDateString', sortable: false, filterable: false },
    { text: 'Payed date', value: 'paidDateString', sortable: false, filterable: false },
    { text: 'Organization ', value: 'contrAgentName', sortable: false, filterable: false },
    { text: 'Service', value: 'service', sortable: false, filterable: false },
    { text: 'Sum of act (uah)', value: 'actAmountString', sortable: false, filterable: false },
    { text: 'Sum of commission (uah)', value: 'commissionAmountString', sortable: false, filterable: false }
  ]

  static ormActions = []
}
