const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['loadCurrentUser'] = require('../middleware/loadCurrentUser.js')
middleware['loadCurrentUser'] = middleware['loadCurrentUser'].default || middleware['loadCurrentUser']

middleware['loadOrganizations'] = require('../middleware/loadOrganizations.js')
middleware['loadOrganizations'] = middleware['loadOrganizations'].default || middleware['loadOrganizations']

middleware['superAdmin'] = require('../middleware/superAdmin.js')
middleware['superAdmin'] = middleware['superAdmin'].default || middleware['superAdmin']

export default middleware
