<template lang="pug">
    div
      v-overlay(:value="drawer" z-index="4")
      v-navigation-drawer(
        v-model="drawer"
        app
        clipped
        disable-resize-watcher
        right
        hide-overlay
        :style="{ top: this.$vuetify.application.top + 'px', zIndex: 4 }"
      )
        v-list(
          nav
          dense
          class="flex-grow-1"
        )
          v-list-item-group
            template(v-for="(item, i) in items")
              v-list-item(
                v-if="getParam(item.visible, $User, true) && (('model' in item) ? $ability.can(ormActions.read, item.model) : true)"
                :key="i"
              )
                nuxt-link(:to="item.href" class="header-link"  active-class="active" exact) {{ _.upperFirst($t(item.text)) }}
            v-list-item
              nuxt-link(to="/profile" class="header-link"  active-class="active" exact) {{ $t('Account settings') }}
            v-list-item
              div(@click="logout") {{ $t('Logout from account') }}
      v-app-bar(
        clipped-right
        app
        :height="xsDevice ? 70 : 90"
        class="header"
      )
        v-row(no-gutters)
          v-col(cols="6" class="d-flex align-center")
            div(class="d-flex align-center mr-8 pointer" @click="onMenuItemClick('/dashboard')")
              v-img(
                :src="require('~/assets/images/logo/small-logo.png')"
                :height="'40px'"
                :width="'40px'"
                :position="'center'"
              )
            v-item-group(class="d-flex" v-if="!xsDevice")
              template(v-for="(item, i) in items")
                  v-item(
                    v-if="getParam(item.visible, $User, true) && (('model' in item) ? $ability.can(ormActions.read, item.model) : true)"
                    :key="i"
                  )
                    nuxt-link(:to="item.href" class="header-link"  active-class="active" exact) {{ _.upperFirst($t(item.text)) }}
          v-col(cols="6" class="d-flex align-center" :class="{'flex-row-reverse': xsDevice, 'justify-end': !xsDevice}")
            div(v-if="xsDevice" class="ml-5")
              div(class="burger-menu" :class="{'active': drawer}" @click.stop="drawer = !drawer")
                span
                span
                span
            div(class="header-icons-item" v-if="superAdminToken")
              e-link( @click="backToAdmin" title="Повернутися в адмінку")
                e-svg-icon(name="activation" size="lg")
            v-menu(
              v-if="!xsDevice"
              v-model="supportMenu"
              content-class="menu-shift menu-shift--left"
            )
              template(v-slot:activator="{ on }")
                div(
                  class="header-icons-item"
                  :class="{ 'active': supportMenu }"
                  @click="supportMenu = !supportMenu"
                )
                  e-svg-icon(v-if="!supportMenu" name="headset" size="lg")
                  e-svg-icon(v-else name="headset-white" size="lg")
              v-list
                v-list-item
                  m-support-buttons(shouldLoadHelpDesk)
            div(v-else class="header-icons-item" @click="supportMobile")
              e-svg-icon(name="headset" size="lg")
            v-menu(
              v-if="!xsDevice"
              v-model="accountMenu"
              content-class="menu-shift"
            )
              template(v-slot:activator="{ on }")
                div(
                  class="header-icons-item"
                  :class="{ 'active': accountMenu }"
                  @click="accountMenu = !accountMenu"
                )
                  e-svg-icon(v-if="!accountMenu" name="person" size="lg")
                  e-svg-icon(v-else name="person-white" size="lg")
              v-list
                v-list-item
                  span(class="account-link account-link--primary" @click="goToProfile") {{ $t('Account settings') }}
                v-list-item
                  span(class="account-link account-link--error" @click="logout") {{ $t('Logout from account') }}
</template>

<script>
import ELink from '~/components/elements/links/e-link'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import MSupportButtons from '~/components/modules/m-support-buttons'
import { menuItems } from '~/const/menuItems'
import contentDialog from '~/mixins/dialogs/contentDialog'
import EButtonMain from '~/components/elements/buttons/e-button-main'
import taxAuth from '~/mixins/pages/tax-auth'
import auth from '~/modules/auth/auth'
import Organization from '~/models/directories/Organization'
import responsive from '~/mixins/pages/responsive'

export default {
  components: {
    ELink,
    ESvgIcon,
    MSupportButtons,
    EButtonMain
  },
  mixins: [contentDialog, taxAuth, responsive],
  data: () => ({
    drawer: false,
    windowWidth: window.innerWidth,
    accountMenu: false,
    supportMenu: false
  }),
  computed: {
    items () {
      return menuItems
    },
    hasOrganization () {
      return Organization.exists()
    },
    superAdminToken () {
      return auth.getSuperAdminAuthToken()
    }
  },
  watch: {
    windowWidth (val) {
      if (val > this.xsDevice) {
        this.drawer = false
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onMenuItemClick (route) {
      if (route) {
        this.$router.push(route)
      }
    },
    getParam (param, item, def) {
      if (param === undefined) {
        return def
      }
      if (typeof param === 'function') {
        return param(item, this.$Organization)
      }
      return param
    },
    async supportMobile () {
      await this.contentDialog.open({
        component: 'm-support-buttons',
        componentProps: {
          alternativeMobile: true
        }
      })
    },
    goToProfile () {
      this.$router.push('/profile')
    },
    async logout () {
      await auth.logout()
      this.destroyIitGlobalDialog()
      await this.$router.push({ name: 'auth-login' })
    },
    async backToAdmin () {
      await auth.applyAdminToken()
      this.$router.push('/admin/organizations')
    },
    onResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-color: #ffffff !important;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  width: 100% !important;
  height: 90px !important;
  box-shadow: none;

  @media (max-width: map-get($breakpoints, 'xs')) {
    height: 70px !important;
  }
}

.header-link {
  font-weight: 500;
  color: #070600;
  text-decoration: none;

  position: relative;

  &:not(:last-child) {
    margin-right: 32px;
  }

  &:before {
      width: 0;
      height: 2px;
      background: black;
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      z-index: 5;

      transition: width 0.2s linear;
    }
  &.active {
    &:before {
        width: 100%;
      }
  }
  &:hover {
    &:before {
        width: 100%;
      }
  }
}

.header-icons-item {
  width: 40px !important;
  height: 40px !important;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(7, 6, 0, 0.06);
  border-radius: 28px;

  cursor: pointer;
  margin: 0 10px;
  transition: ease-in 0.3s;

  &.active {
    background: $primary-light;
  }
}

.add-org_wrapper {
  text-align: center;
  padding-bottom: 100px;
}

.v-menu__content {
  border-radius: 16px!important;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.08)!important;
}

.menu-shift {
  top: 70px !important;
}

.menu-shift--left {
  left: unset !important;
  right: 80px !important;
}

.v-list {
  border-radius: 16px!important;
  padding: 16px 0!important;
}

.v-list-item {
  padding: 0 20px!important;
}

.account-link {
  cursor: pointer;
}

.account-link--primary:hover, .account-link--primary:active {
  color: $primary-light;
}

.account-link--error:hover, .account-link--error:active {
  color: $danger;
}

.burger-menu {
  margin: 20px auto;
  width: 20px;
  height: 14px;
  cursor: pointer;
  position: relative;
}
.burger-menu span {
  background-color: #070600;
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0;
  transition: all 0.3s ease;
}
.burger-menu span:first-child {
  top: 0;
}
.burger-menu span:nth-child(2) {
  top: 6px;
}
.burger-menu span:last-child {
  top: 12px;
}
.burger-menu.active span:nth-child(2) {
  opacity: 0;
}
.burger-menu.active span:first-child,
.burger-menu.active span:last-child {
  top: 6px;
}
.burger-menu.active span:first-child {
  transform: rotate(45deg);
}
.burger-menu.active span:last-child {
  transform: rotate(-45deg);
}

</style>
