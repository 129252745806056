<template lang="pug">
    div
        v-form(v-if="!showOrganizationDataForm" class="pa-5")
            h1(class="form-title") {{ $t(title) }}
            p(class="form-text") {{ $t(text) }}
            ValidationObserver(ref="form")
                template(v-for="(checkbox, idx) in checkboxes")
                    ValidationProvider(:name="$t(checkbox.label)" v-slot="{ errors }" :key="idx")
                        v-checkbox(
                            v-model="partners[checkbox.model]"
                            :error-messages="errors"
                            :label="$t(checkbox.label)"
                            class="mt-0"
                            :input-value="checkbox.model"
                            :disabled="organizationRegistered[checkbox.model]"
                        )
                            template(v-slot:append)
                                div(class="icon-wrapper")
                                    img(:src="getIconUrl(checkbox.icon)")
                v-row(class="pt-3")
                    v-col(cols="6" offset="6")
                        v-btn(
                            class="main-button w-100"
                            @click="checkParallelRegistration"
                            :disabled="isAllPartnersRegistered"
                            :loading="connectSystemLoading || checkRegistrationLoading"
                        ) {{ $t('Connect') }}
        m-form-connect-organization(
            v-else
            :organization="organization"
            :partner-name="neededFormPartnerName"
            :showFullForm="partners.bookKeeper || partners.masterBuh"
            :is-form-opened-in-another-component="true"
            @close="connectPartners"
        )
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import partners from '~/modules/partners/mixins/partners'
import Partners from '~/modules/partners/models/Partners'
import MFormConnectOrganization from '~/components/modules/forms/concrete/m-form-connect-organization'

export default {
  components: {
    MFormConnectOrganization,
    ValidationObserver,
    ValidationProvider,
    ESvgIcon
  },
  mixins: [partners],
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    organization: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    partners: {
      dnTrade: false,
      masterBuh: false,
      bookKeeper: false,
      hugeProfit: false
    },
    connectSystemLoading: false,
    checkRegistrationLoading: false,
    registeredPartners: {
      dnTrade: false,
      bookKeeper: false,
      hugeProfit: false
    },
    partnerLinks: {
      dnTrade: null,
      masterBuh: null,
      bookKeeper: null,
      hugeProfit: null
    },
    showOrganizationDataForm: false,
    partnersToConnect: [],
    neededFormPartnerName: ''
  }),
  computed: {
    checkboxes () {
      return [
        {
          label: 'navkolo.trading',
          model: 'dnTrade',
          icon: 'building-24.svg'
        },
        {
          label: 'navkolo.accounting basic',
          model: 'bookKeeper',
          icon: 'yellow-book.svg'
        },
        {
          label: 'navkolo.CRM',
          model: 'hugeProfit',
          icon: 'settings-4.svg'
        },
        {
          label: 'navkolo.accounting pro',
          model: 'masterBuh',
          icon: 'receipt.svg'
        }
      ]
    },
    organizationRegistered () {
      return {
        dnTrade: this.checkOrganizationIntegration(Partners.keys.dnTrade),
        masterBuh: this.checkOrganizationIntegration(Partners.keys.masterBuh),
        bookKeeper: this.checkOrganizationIntegration(Partners.keys.bookKeeper),
        hugeProfit: this.checkOrganizationIntegration(Partners.keys.hugeProfit)
      }
    },
    isAllPartnersRegistered () {
      return this.organizationRegistered.bookKeeper &&
        this.organizationRegistered.dnTrade &&
        this.organizationRegistered.hugeProfit &&
        this.organizationRegistered.masterBuh
    }
  },
  watch: {
    partners: {
      deep: true,
      handler (val) {
        Object.entries(val).map(([partner, isConnected]) => {
          if (isConnected && !this.checkOrganizationIntegration(Partners.keys[partner])) {
            if (!this.partnersToConnect.find(item => item === partner)) {
              this.partnersToConnect = [...this.partnersToConnect, partner]
            }
          } else if (!isConnected) {
            const elemIndex = this.partnersToConnect.findIndex(item => item === partner)

            if (elemIndex >= 0) {
              this.partnersToConnect.splice(elemIndex, 1)
            }
          }
        })
      }
    },
    partnersToConnect (arr) {
      if (!arr.length) {
        this.neededFormPartnerName = ''
        return
      }
      const theMostDemandingPartner = arr.find(partnerName => ['bookKeeper', 'masterBuh'].includes(partnerName))
      const averageDemandingPartner = arr.find(partnerName => partnerName === 'dnTrade' && !['bookKeeper', 'masterBuh'].includes(partnerName))

      if (theMostDemandingPartner) {
        this.neededFormPartnerName = 'bookKeeper'
      } else if (averageDemandingPartner) {
        this.neededFormPartnerName = 'dnTrade'
      } else {
        this.neededFormPartnerName = 'hugeProfit'
      }
    }
  },
  async created () {
    this.checkboxes.forEach((item) => {
      this.partners[item.model] = this.organizationRegistered[item.model]
    })
    this.checkRegistrationLoading = true
    await Promise.all([
      this.checkPartnerSuccessRegistration('dnTrade'),
      this.checkPartnerSuccessRegistration('masterBuh'),
      this.checkPartnerSuccessRegistration('bookKeeper'),
      this.checkPartnerSuccessRegistration('hugeProfit')
    ])
    this.checkRegistrationLoading = false
  },
  methods: {
    checkOrganizationIntegration (key) {
      if (!this.organization.organizationIntegration.length) {
        return false
      } else {
        return this.organization.organizationIntegration.some(item => item.status === 'success' && item.userIntegration.provider === key)
      }
    },
    getIconUrl (icon) {
      return require('~/assets/sprite/svg/' + icon)
    },
    checkParallelRegistration () {
      if (this.neededFormPartnerName === 'bookKeeper' && !this.organization.personType) {
        this.showOrganizationDataForm = true
      } else {
        this.connectPartners()
      }
    },
    async connectPartners () {
      this.showOrganizationDataForm = false
      const connected = []
      for (const partner in this.partners) {
        if (this.partners[partner] !== false) {
          if (!this.registeredPartners[partner]) {
            if (await this.connectSystem(partner, [this.organization])) {
              connected.push(partner)
            }
          } else if (!this.organizationRegistered[partner]) {
            if (await this.addOrganization(partner, this.organization)) {
              connected.push(partner)
            }
          }
        }
      }
      if (connected.length) {
        this.$attrs.closeModal(true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: linear-gradient(0deg, #000000, #000000), #161B25;
  border-radius: 100%;
}
.form-title {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
}
.form-text {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(7, 6, 0, 0.7);
}
</style>
