<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") Реєстрація успішна!
    div
      p(class="mb-3") Перейти в систему партнера?
    div(class="dialog__actions")
      a(
        :href="link"
        target="_blank"
        class="main-button redirect__link"
        @click="$attrs.closeModal"
      ) Перейти
</template>

<script>
export default {
  name: 'BlockPartnerRegistrationConfirm',
  props: {
    link: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  text-align: center;
}

.redirect__link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px !important;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
}
</style>
