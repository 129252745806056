import Cookies from 'js-cookie'
import auth from '~/modules/auth/auth'

export default function ({ store, redirect, error, query }) {
  if (!auth.isAuthenticated()) {
    if (query.p) {
      Cookies.set('promotionCode', query.p, { expires: 30 })
      return redirect({
        path: '/auth/registration'
      })
    }
    const route = store?.$router?.history?.current?.path
    return redirect({
      path: '/auth/login',
      query: {
        from: route
      }
    })
  }
}
