<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title text-center") {{ $t('The demo account is publicly available') }}
    div
      p(class="mb-4") {{ $t('A demo account is intended to demonstrate the product\'s capabilities and is available to other system users') }}
      p(class="mb-4 red--text") {{ $t('Do not post sensitive information there as it will be publicly available') }}
      p {{ $t('Do you agree that you do not need to enter sensitive information') }}
    div(class="dialog__actions")
      a(
        :href="link"
        target="_blank"
        class="main-button redirect__link"
        @click="closeModal"
      ) {{ $t('Agree') }}
</template>

<script>
export default {
  name: 'MBlockDemoAccountConfirm',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    link: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.redirect__link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px !important;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
}
</style>
