<template lang="pug">
  v-stepper(v-model="step" v-bind="stepper.stepperAttrs" @change="onChange")
    v-stepper-header()
      template( v-for="n in stepper.steps")
        v-stepper-step(
          :key="`${n}-step`"
          :complete="step > n"
          :step="n"
          v-bind="stepper.stepAttrs"
          :rules="stepRules(n)"
        )
          | {{$t(generateText(n))}}
        v-divider(
          v-if="n !== stepper.steps && stepper.stepperAttrs.divider"
          :key="n"
        )
    v-stepper-items()
      v-stepper-content(
        v-for="n in stepper.steps"
        :key="`${n}-content`"
        :step="n"
      )
        component(
          :is="stepper.stepComponents[n-1].component"
          v-bind="{...stepper.stepComponents[n-1].attrs}"
          :stepperData.sync="dataBag",
          :step.sync="step"
          v-on="$listeners"
        )

</template>

<script>
import Stepper from '~/view_configs/modules/stepper/Stepper'
import TOrmFieldsContentWrapper from '~/components/templates/wrappers/t-orm-fields-content-wrapper'
import MBlockRegisterFinish from '~/modules/registration/views/components/m-block-register-finish'
import MBlockSetOrganization from '~/components/modules/blocks/concrete/m-block-set-organization'

export default {
  name: 'MStepper',
  components: {
    TOrmFieldsContentWrapper,
    MBlockSetOrganization,
    MBlockRegisterFinish
  },
  props: {
    stepper: {
      type: Stepper,
      required: true
    },
    invalidSteps: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      step: this.stepper.initialStep,
      dataBag: {}
    }
  },
  computed: {
    nextEmptyStep () {
      if (this.step < this.stepper.steps && !this.stepper.stepperAttrs.divided) {
        return this.step + 1
      }
      return null
    }
  },
  methods: {
    generateText (step) {
      return this.stepper.texts[step - 1]
    },
    goTo (step) {
      this.step = step
      this.$emit('change', { step: this.step, nextEmptyStep: this.nextEmptyStep })
      return true
    },
    goToNext () {
      if (this.step === this.stepper.steps) { return false }
      this.step++
      this.$emit('change', { step: this.step, nextEmptyStep: this.nextEmptyStep })
      return true
    },
    stepRules (step) {
      if (this.invalidSteps.includes(step)) {
        return [() => false]
      }
    },
    onChange (step) {
      this.$emit('change', { step, nextEmptyStep: this.nextEmptyStep })
    },
    setData (key, data) {
      this.dataBag[key] = data
    },
    removeData (key) {
      delete this.dataBag[key]
    },
    clearData () {
      this.dataBag = {}
    }
  }
}
</script>

<style scoped>

</style>
