import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import { DateTime } from '~/services/_utils/DateTime'

export default class Debt extends ChainInheritance(Administration, [
  SoftDeletAble,
  TimestampAble,
  PersistAble
]) {
  static entity = 'debts'
  static paginated = true
  static ormLoadWithRelations = true

  static STATUS_NEW = 'invoiced'
  static STATUS_PAYED = 'payed'
  static STATUS_NOT_PAYED = 'not_payed'
  static STATUS_FREE = 'free'

  static fields () {
    return super.fields({
      id: this.attr(null),
      sum: this.attr(null),
      sumInitial: this.attr(null),
      discount: this.attr(null),
      status: this.attr(null),
      payedAt: this.attr(null)
    })
  }

  get payedAtString () {
    return DateTime.format(this.payedAt)
  }
}
