<template lang="pug">
  v-form(class="change-name py-8")
    h2(class="mb-6") {{ $t('Change password') }}
    ValidationObserver(ref="changePasswordForm")
      t-orm-fields(v-bind.sync="dataForm" :items="fields")
    v-btn(
      class="main-button w-100"
      :loading="loading"
      @click="submit"
    ) {{ $t('Change') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import User from '~/models/administration/User'

export default {
  name: 'MFormChangePassword',
  components: {
    TOrmFields
  },
  data: () => ({
    dataForm: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: ''
    },
    loading: false
  }),
  computed: {
    fields () {
      return [
        {
          model: 'currentPassword',
          component: 'e-input-password',
          provider: {
            name: 'Current password',
            vid: 'currentPassword',
            rules: 'required|min:6',
            mode: 'lazy'
          },
          attrs: {
            label: 'Current password',
            type: 'password',
            outlined: true,
            required: true
          },
          classes: ['app-filled-input']
        },
        {
          model: 'newPassword',
          component: 'e-input-password',
          provider: {
            name: 'New password',
            vid: 'password',
            rules: 'required|min:6',
            mode: 'lazy'
          },
          attrs: {
            label: 'New password',
            type: 'password',
            outlined: true,
            required: true,
            showPasswordStrength: true
          },
          classes: ['app-filled-input']
        },
        {
          model: 'newPasswordConfirmation',
          component: 'e-input-password',
          provider: {
            name: 'Confirm password',
            vid: 'passwordConfirmation',
            rules: 'required|confirmed:password',
            mode: 'lazy'
          },
          attrs: {
            label: 'Confirm password',
            type: 'passwordConfirmation',
            outlined: true,
            required: true
          },
          classes: ['app-filled-input']
        }
      ]
    }
  },
  methods: {
    async submit () {
      const valid = await this.$refs.changePasswordForm.validate()

      if (valid) {
        try {
          this.loading = true
          await User.api().updateUserPassword(this.dataForm)

          this.$attrs.closeModal(true, true)
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.change-name {
  color: $dark;
}
</style>
