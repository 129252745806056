<template lang="pug">
  t-dialog(
    v-bind="$attrs"
    :value="isOpened"
    v-on="$listeners"
    :max-width="width"
    :showCloseBtn="!hideCloseButton"
    @input="close"
  )
    template(#title v-if="title" )
      p(class='ma-0 pa-0' :class="titleClass") {{title}}
    template(#subtitle v-if="subtitle" )
      p(class='text-center ma-0 mt-2 pa-0') {{subtitle}}
    template(#content)
      v-row()
        v-col(cols="12" v-if="text")
          | {{text}}
        v-col(cols="12" v-if="component")
          component(:is="component" v-bind="{...componentProps, isOpened}" :closeModal="onCloseEvent" v-on="componentListeners")
    template(#actions v-if="showActions" )
      v-row(no-gutters justify="center" )
        v-col(cols="6" v-if="buttonText.approve")
          v-btn(@click="confirm" class="main-button plain-error w-100")
            | {{buttonText.approve}}
        v-col(cols="6" v-if="buttonText.dismiss")
          v-btn(@click="close" class="ml-4 main-button w-100")
            | {{buttonText.dismiss}}
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TDialog from '~/components/templates/t-dialog'
import Dialog from '~/models/system/Dialog'

// used components
// TODO this must be rethink
import MFormBlock from '~/components/modules/blocks/m-form-block'
import MOrmTable from '~/components/modules/tables/orm/m-orm-table'
import MBlockSetOrganization from '~/components/modules/blocks/concrete/m-block-set-organization'
import BlockNotificationsList from '~/modules/notifications/views/components/block-notifications-list'
import BlockSingleNotification from '~/modules/notifications/views/components/block-single-notification'
import BlockCreatePartnerSystemAccount from '~/modules/partners/views/components/block-create-partner-system-account'
import BlockPartnerRegistrationConfirm from '~/modules/partners/views/components/block-partner-registration-confirm'
import MFormConnectOrganization from '~/components/modules/forms/concrete/m-form-connect-organization'
import MFormConnectPartners from '~/components/modules/forms/concrete/m-form-connect-partners'
import MFormCreateInvoice from '~/components/modules/forms/concrete/billing/m-form-create-invoice'
import MSupportButtons from '~/components/modules/m-support-buttons'
import MBlockDemoAccountConfirm from '~/modules/dashboard/components/m-block-demo-account-confirm'
import MFormSelectOrganizations from '~/components/modules/forms/concrete/m-form-select-organizations'
import MFormChangeName from '~/components/modules/forms/concrete/profile/m-form-change-name'
import MFormChangePhone from '~/components/modules/forms/concrete/profile/m-form-change-phone'
import MFormChangePassword from '~/components/modules/forms/concrete/profile/m-form-change-password'
import MBlockConnectCheckbox from '~/components/modules/blocks/concrete/dashboard/m-block-connect-checkbox'

export default {
  components: {
    MFormSelectOrganizations,
    MFormCreateInvoice,
    TOrmButtons,
    TDialog,
    QrcodeVue,
    MFormBlock,
    MOrmTable,
    MBlockSetOrganization,
    BlockNotificationsList,
    BlockSingleNotification,
    BlockCreatePartnerSystemAccount,
    BlockPartnerRegistrationConfirm,
    MFormConnectOrganization,
    MFormConnectPartners,
    MSupportButtons,
    MBlockDemoAccountConfirm,
    MFormChangeName,
    MFormChangePhone,
    MFormChangePassword,
    MBlockConnectCheckbox
  },
  props: {
    type: {
      type: String,
      default: 'content'
    }
  },
  computed: {
    dialog () {
      return Dialog.query().where('type', this.type).first()
    },
    isOpened () {
      return this._.get(this.dialog, 'isOpened', false)
    },
    params () {
      return this._.get(this.dialog, 'params', '')
    },
    title () {
      const title = this._.get(this.params, 'title', '')
      return this._.isFunction(title) ? title(this) : this.$t(title)
    },
    subtitle () {
      const subtitle = this._.get(this.params, 'subtitle', '')
      return this._.isFunction(subtitle) ? subtitle(this) : this.$t(subtitle)
    },
    text () {
      const text = this._.get(this.params, 'text', '')
      return this._.isFunction(text) ? text(this) : this.$t(text)
    },
    hideCloseButton () {
      return this._.get(this.params, 'hideCloseButton', false)
    },
    showActions () {
      return this._.get(this.params, 'showActions', false)
    },
    component () {
      return this._.get(this.params, 'component')
    },
    componentProps () {
      return this._.get(this.params, 'componentProps')
    },
    componentListeners () {
      return this._.get(this.params, 'componentListeners')
    },
    buttonText () {
      return this._.get(this.params, 'buttonText')
    },
    width () {
      return this._.get(this.params, 'width', '800px')
    },
    titleClass () {
      return this._.get(this.params, 'titleClass', 'text-center')
    },
    onClose () {
      return this._.get(this.params, 'onClose')
    }
  },
  created () {
    if (!this.dialog) {
      Dialog.insert({
        data: {
          type: this.type
        }
      })
    }
  },
  methods: {
    async close () {
      if (this._.isFunction(this.onClose)) {
        await this.onClose()
      }
      await this.dialog.close(false, true)
    },
    confirm () {
      this.dialog.close(true, true)
    },
    onCloseEvent (payload, confirmed = true) {
      this.dialog.close(payload, confirmed)
    }
  }
}
</script>
