<template lang="pug">
    v-alert(
      :dense="dense"
      :dismissible="dismissible"
      class="alert"
      :class="`alert--${type}`"
    )
      span(class="drawer--text")
        h2(
          class="alert__title"
          :class="`alert__title--${type}`"
        )
          slot(name="title")
        p(class="alert__text")
          slot(name="text")
      template(#prepend)
        img(
          :src="require(`~/assets/sprite/svg/${icon}.svg`)"
          class="pr-5"
        )

</template>

<script>
export default {
  props: {
    dense: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'warning',
      validator: (value) => {
        return ['error', 'warning'].includes(value)
      }
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    hideIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon () {
      if (this.hideIcon) { return false }
      switch (this.type) {
        case 'warning': return 'bell-reminder'
        case 'error' : return 'exclamation-warning-2'
        default : return null
      }
    },
    color () {
      switch (this.type) {
        case 'warning': return 'yellow'
        case 'error' : return 'red'
        default : return null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.alert {
  width: 100%;
  border-left: 4px solid;
  border-radius: 16px;

  &--warning {
    background: #FDFFEB !important;
    border-color: #DFFF00 !important;
  }
  &--error {
    background: #FFF3F8 !important;
    border-color: #FF1744;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;

    &--warning {
      color: #000000;
    }
    &--error {
      color: $danger;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
  }
}
</style>
