import signPluginCommonDialog from '~/mixins/dialogs/signPluginCommonDialog'
import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import taxAuth from '~/mixins/pages/tax-auth'

const addOrgAutomaticEds = {
  mixins: [taxAuth, signPluginCommonDialog],

  data: () => ({
    orgDataLoading: false,
    orgExists: false,
    showOrgData: null,
    orgData: {
      ownedOrganization: {}
    }
  }),

  created () {
    this.destroyIitGlobalDialog()
  },

  methods: {
    checkPersonType (code = '') {
      // for old passport type (8 symbols)
      if (isNaN(parseInt(code))) {
        return 'individual'
      }
      // edrpou always 8 symbols
      return code.toString().length === 8 ? 'juridical' : 'individual'
    },

    async createOrgAutomatically () {
      const keyData = await this.signPluginCommonDialog.open()
      if (!keyData) {
        this.$emit('prev')
        await this.signPluginCommonDialog.close()
        return null
      }
      const edsKeyData = new EdsKeyData(keyData)

      if (edsKeyData.isSeal()) {
        this.$handlers.error('Please use the EDS key instead of seal', this)
        await this.signPluginCommonDialog.close()
        return this.createOrgAutomatically()
      }

      const sign = await this.signPluginCommonDialog.interface.sign(edsKeyData.identity)
      await this.signPluginCommonDialog.close()

      try {
        this.orgDataLoading = true
        const dataFromSfs = await this.$store.dispatch('user/getOrganizationFromTax', {
          EDRPOUCode: edsKeyData.edrpouCode,
          DRFOCode: edsKeyData.drfoCode,
          sign
        }) || {}

        if (this._.get(dataFromSfs, 'exists')) {
          this.orgExists = true
          this.$notification.error(this.$t('Organization exists'))
        } else {
          const name = this._.get(dataFromSfs, 'identify.FULL_NAME', '')
          const shortName = this._.get(dataFromSfs, 'identify.NAME', '') || name
          const edrpou = edsKeyData.identity || this._.get(dataFromSfs, 'identify.TIN', '')
          const taxNumber = this._.get(dataFromSfs, 'vat.KOD_PDV', '') || edsKeyData.identity
          const personType = this.checkPersonType(edsKeyData.identity)
          const personShowType = personType === 'juridical' ? 'Legal entity' : 'Individual'
          const isVAT = Boolean(this._.get(dataFromSfs, 'vat', null)) && !this._.get(dataFromSfs, 'vat.DAT_ANUL', null)
          const isSingleTax = Boolean(this._.get(dataFromSfs, 'singleTax', null)) && !this._.get(dataFromSfs, 'singleTax.DAT_ANUL', null)
          const token = this._.get(dataFromSfs, 'token')
          delete dataFromSfs.token
          delete dataFromSfs.exists
          this.$set(this.$data, 'orgData', {
            ownedOrganization: {
              name,
              shortName,
              edrpou,
              taxNumber,
              personType,
              isVAT,
              isSingleTax,
              info: {
                taxInfo: dataFromSfs
              }
            },
            token
          })

          this.showOrgData = {
            Name: name,
            Edrpou: edrpou,
            'Person type': this.$t(personShowType),
            Director: edsKeyData.subjFullName
          }

          if (!name || (!isVAT && !isSingleTax)) {
            this.showManuallyData = true
          }
        }
      } catch (e) {
        this.$handlers.error(e, this)
        this.$handlers.error(new Error('Failed registration. Info: ' + JSON.stringify({
          identity: edsKeyData.identity,
          sign
        })), this, true)
      } finally {
        this.orgDataLoading = false
      }
    }
  }
}

export default addOrgAutomaticEds
