import Partners from '~/modules/partners/models/Partners'
import Organization from '~/models/directories/Organization'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import contentDialog from '~/mixins/dialogs/contentDialog'
import informationDialog from '~/mixins/dialogs/informationDialog'

const partners = {
  mixins: [contentDialog, informationDialog],
  methods: {
    async connectSystem (key, organizations = []) {
      let allowToConnect = true
      try {
        if (this?.item?.key === 'checkbox') {
          this.$emit('emitAnotherPartnerLoading', { key, isLoading: true })
        } else {
          this.connectSystemLoading = true
        }
        const loadingDialog = this.informationDialog

        if (!this.$route.path.includes('dashboard/myorganizations')) {
          loadingDialog.open(this.additionalLoadingDialogConfig)
        }
        for (let i = 0; i < organizations.length; i++) {
          allowToConnect = allowToConnect && (await this.canConnectToPartner(key, organizations[i].id))
        }
        if (allowToConnect) {
          const statusData = await this.registerToPartnerSystem(key, organizations)
          loadingDialog.close()
          if (this._.get(statusData, 'status') === 'error') {
            return this.$handlers.error(this._.get(statusData, 'errorMessage'), this)
          }
          const isConnected = await this.checkPartnerSuccessRegistration(key)
          return { isConnected, link: this._.get(statusData, 'link') }
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        if (this?.item?.key === 'checkbox') {
          this.$emit('emitAnotherPartnerLoading', { key, isLoading: false })
        } else {
          this.connectSystemLoading = false
        }
      }
    },
    async addOrganization (key, organization) {
      try {
        this.connectSystemLoading = true
        const allowToConnect = await this.canConnectToPartner(key, organization.id)
        if (allowToConnect) {
          const organizationIntegration = await this.addOrganizationToPartnerSystem(key, organization)
          if (this._.get(organizationIntegration, 'status') === 'error') {
            return this.$handlers.error(this._.get(organizationIntegration, 'errorMessage'), this)
          }
          return organizationIntegration
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.connectSystemLoading = false
      }
    },
    checkPartnerRegistrationStatus (key, id) {
      const translateKeyToUrl = this._.kebabCase(key)
      const resolveStatus = 'success'
      const rejectStatus = 'error'
      const request = new IntervalRequest(() => Partners.api().partnerRegistrationStatusCheck(translateKeyToUrl, id), {
        maxDelay: 6e5
      })
      return request.startExponential(
        response =>
          (this._.get(response, 'response.data.status', null) === resolveStatus && this._.get(response, 'response.data.link', null)) ||
          this._.get(response, 'response.data.status', null) === rejectStatus
      )
    },
    checkAddOrganizationStatus (key, id) {
      const translateKeyToUrl = this._.kebabCase(key)
      const resolveStatus = 'success'
      const rejectStatus = 'error'
      const request = new IntervalRequest(() => Partners.api().addOrganizationStatusCheck(translateKeyToUrl, id), {
        maxDelay: 6e5
      })
      return request.startExponential(
        response =>
          (this._.get(response, 'response.data.status', null) === resolveStatus ||
          this._.get(response, 'response.data.status', null) === rejectStatus)
      )
    },
    checkPartnerAuthStatus (key, id) {
      const translateKeyToUrl = this._.kebabCase(key)
      const resolveStatus = 'success'
      const rejectStatus = 'error'
      const request = new IntervalRequest(() => Partners.api().partnerRegistrationStatusCheck(translateKeyToUrl, id), {
        maxDelay: 6e5
      })
      return request.startExponential(
        response =>
          (this._.get(response, 'response.data.status', null) === resolveStatus && this._.get(response, 'response.data.link', null)) ||
          this._.get(response, 'response.data.payload.auth', null) === rejectStatus
      )
    },
    async registerToPartnerSystem (key, organizations) {
      const translateKeyToUrl = this._.kebabCase(key)
      const organizationIds = this._.map(organizations, org => this._.get(org, 'id'))
      const taskId = this._.get(await Partners.api().partnerRegistration(translateKeyToUrl, { organizationIds }), 'response.data.id')
      return this._.get(await this.checkPartnerRegistrationStatus(key, taskId), 'response.data', {})
    },
    async addOrganizationToPartnerSystem (key, organization) {
      const translateKeyToUrl = this._.kebabCase(key)
      const taskId = this._.get(await Partners.api().addOrganizationToPartner(translateKeyToUrl, organization.id), 'response.data.id')
      return this._.get(await this.checkAddOrganizationStatus(key, taskId), 'response.data', {})
    },
    async canConnectToPartner (key, organizationId) {
      const translateKeyToUrl = this._.kebabCase(key)
      const isAbleToConnect = this._.get(await Partners.api().partnerCheckSupport(organizationId, translateKeyToUrl), 'response.data.support')
      const organization = Organization.query().where('id', organizationId).get()

      if (!isAbleToConnect) {
        if (this.$route.name === 'dashboard') {
          return await this.contentDialog.open({
            width: '730px',
            component: 'm-form-connect-organization',
            componentProps: {
              organization: organization[0],
              showFullForm: ['masterBuh', 'bookKeeper'].includes(this.item.key),
              partnerName: this.item.key === 'checkbox' ? key : this.item.key
            }
          })
        } else {
          this.showOrganizationDataForm = true
        }
      } else if (isAbleToConnect && ['masterBuh', 'bookKeeper'].includes(key) && organization.personType) {
        return await this.contentDialog.open({
          width: '730px',
          component: 'm-form-connect-organization',
          componentProps: {
            organization: organization[0],
            partnerName: this.item.key
          }
        })
      } else {
        return true
      }
    },
    async checkPartnerSuccessRegistration (partnerName) {
      const translateKeyToUrl = this._.kebabCase(partnerName)
      try {
        const key = this._.get(await Partners.api().partnerGetSuccessRegistration(translateKeyToUrl), 'response.data', {})
        this.$set(this.registeredPartners, partnerName, this._.get(key, 'status') === 'success')
        this.$set(this.partnerLinks, partnerName, this._.get(key, 'link'))
        return true
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
export default partners
