<template lang="pug">
    div
      div(class="d-flex align-center mb-3")
        e-svg-icon(name="arrow-narrow-left" class="cursor-pointer" @click="backToChoiceMenu")
        h1(class="dialog-title pl-5") {{ $t('Add organization manually') }}
      p(class="dialog-text mb-6") {{ $t('Enter information manually and click save') }}
      ValidationObserver(
          ref="orgName"
          slim
        )
        e-input-wrapper(:item="organizationNameField" filled v-model="orgName" @keyup.native.enter="addManuallyOrganization")
      div(class="d-flex align-center justify-end")
        e-button-main(color="#00BBF9" width="50%" border-radius="16" @click="addManuallyOrganization" :loading="loading") {{ $t('Add') }}
</template>

<script>
import EButtonMain from '~/components/elements/buttons/e-button-main'
import EInputWrapper from '~/components/elements/inputs/e-input-wrapper'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import informationDialog from '~/mixins/dialogs/informationDialog'
import Organization from '~/models/directories/Organization'

export default {
  components: {
    EButtonMain,
    EInputWrapper,
    ESvgIcon
  },
  mixins: [informationDialog],
  data: () => ({
    orgName: '',
    loading: false
  }),
  computed: {
    organizationNameField () {
      return {
        label: {
          text: 'General information',
          className: 'mb-3'
        },
        model: 'organizationName',
        component: 'v-text-field',
        provider: {
          name: 'Name',
          vid: 'name',
          rules: 'required'
        },
        attrs: {
          label: 'Organization full name',
          required: true,
          filled: true
        },
        cols: {
          cols: 12
        }
      }
    }
  },
  methods: {
    backToChoiceMenu () {
      this.$emit('back')
    },
    async addManuallyOrganization () {
      const valid = await this.$refs.orgName.validate()
      if (!valid) {

      } else {
        try {
          this.loading = true

          await Organization.api().addManually({ name: this.orgName })

          this.$emit('closeModal')
          await this.success()
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.loading = false
        }
      }
    },
    async success () {
      await this.informationDialog.open({
        width: '500px',
        title: this.$t('Organization successfully added'),
        text: this.$t('More detailed info you can look at my organizations'),
        textClass: ['justify-center', 'text-center'],
        buttonText: this.$t('Continue'),
        onConfirm: () => this.informationDialog.close()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-title {
  font-family: $card-title-font-family;
  font-weight: $card-title-font-weight;
  font-size: $card-title-font-size;
  line-height: $card-title-line-height;

  @media (max-width: map-get($breakpoints, 'sm')) {
    font-size: $card-title-font-size-mobile;
    line-height: $card-title-line-height-mobile;
  }
}

.dialog-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(7, 6, 0, 0.7);
}
</style>
