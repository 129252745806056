import { Model } from '@vuex-orm/core'
import Download from 'js-file-download'
import { formats } from '~/const/global'
import { DateTime } from '~/services/_utils/DateTime'
import { formatPriceFromApi } from '~/services/_utils/Currency'
import AppNotifications from '~/services/Notifications/AppNotifications'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import CommissionItems from '~/models/partner/CommissionItems'
import Administration from '~/models/abstracts/Administration'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import PersistAble from '~/models/mixins/PersistAble'
import Partners from '~/modules/partners/models/Partners'
import Dialog from '~/models/system/Dialog'

export default class Commission extends ChainInheritance(Administration, [
  SoftDeletAble,
  PersistAble
]) {
  static entity = 'commissions'
  static paginated = true
  static defaultSortOrder = true
  static defaultSortParam = 'date'

  static STATUS_NEW = 'new';
  static STATUS_SENT = 'sent';

  static fields () {
    return super.fields({
      id: this.attr(null),
      date: this.attr(null),
      status: this.attr(null),
      partner: this.attr(null),
      actAmount: this.attr(null),
      commissionAmount: this.attr(null),
      statusChangedAt: this.attr(null),
      filePath: this.attr(null)
    })
  }

  static ormTrans = {
    single: 'Partner commission',
    multy: 'Partner commissions'
  }

  get statusName () {
    switch (this.status) {
      case Commission.STATUS_NEW:
        return 'Не надісланий'
      case Commission.STATUS_SENT:
        return 'Надісланий'
      default:
        return 'n/a'
    }
  }

  get partnerName () {
    switch (this.partner) {
      case Partners.keys.dnTrade:
        return 'DNTrade'
      case Partners.keys.hugeProfit:
        return 'HugeProfit'
      case Partners.keys.bookKeeper:
        return 'BookKeeper'
      case Partners.keys.masterBuh:
        return 'MasterBuh'
      default:
        return 'n/a'
    }
  }

  get dateString () {
    return DateTime.format(this.date, formats.monthYear)
  }

  get statusChangedAtString () {
    return DateTime.format(this.statusChangedAt)
  }

  get commissionAmountString () {
    return formatPriceFromApi(this.commissionAmount)
  }

  get actAmountString () {
    return formatPriceFromApi(this.actAmount)
  }

  static ormHeaders = [
    { text: 'Date', value: 'dateString', sortable: false, filterable: false },
    { text: 'Partner', value: 'partnerName', sortable: false, filterable: false },
    { text: 'Sum of act (uah)', value: 'actAmountString', sortable: false, filterable: false },
    { text: 'Sum of commission (uah)', value: 'commissionAmountString', sortable: false, filterable: false },
    { text: 'Status', value: 'statusName', sortable: false, filterable: false },
    { text: 'Date of change status', value: 'statusChangedAtString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormFilters = [
    {
      model: 'status',
      component: 'v-select',
      provider: {
        vid: 'status',
        name: 'Status'
      },
      attrs: {
        label: 'Status',
        outlined: true,
        clearable: true,
        'return-object': false
      },
      items: () => [Commission.STATUS_NEW, Commission.STATUS_SENT]
    },
    {
      type: 'dateRange',
      model: 'date',
      // component: 'e-input-datetime',
      component: 'e-input-datetime-range',
      attrs: {
        type: 'date',
        label: 'Date',
        range: false,
        clearable: true,
        closeOnClick: true,
        emmitOnlyOnSelectClicked: true,
        'hide-details': true,
        outlined: true
      }
    },
    {
      model: 'partner',
      component: 'v-select',
      provider: {
        vid: 'partner',
        name: 'Partner'
      },
      attrs: {
        label: 'Partner',
        outlined: true,
        clearable: true,
        'return-object': false
      },
      items: () => [Partners.keys.dnTrade, Partners.keys.bookKeeper]
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 4
              },
              fields: [
                'status'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 4
              },
              fields: [
                'date'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 4
              },
              fields: [
                'partner'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = [
    {
      name: 'commission_items',
      text: 'Commission items',
      icon: {
        type: 'e-svg-icon',
        text: 'act'
      },
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        await dialog.open({
          width: 'full',
          title: 'Commission items',
          component: 'm-orm-table',
          componentProps: {
            model: CommissionItems,
            defaultFilter: {
              'commission.id': item.id
            }
          }
        })
      }
    },
    {
      name: 'change_status',
      text: 'Mark as send',
      icon: {
        type: 'e-svg-icon',
        text: 'telegram'
      },
      call: async (item) => {
        try {
          await Commission.api().markAsSend(item.id)
          AppNotifications.success('Комісію партнера позначено, як відправлену.')
        } catch (e) {
          AppNotifications.error(e)
        }
      },
      visible: item => item.status === Commission.STATUS_NEW
    },
    {
      name: 'export',
      text: 'Report export',
      icon: {
        type: 'e-svg-icon',
        text: 'csv'
      },
      call: async (item) => {
        try {
          const res = await Commission.api().export(item.id)
          const blob = new Blob([this._.get(res, 'response.data', '')], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const reportDate = new Date(item.date)
          const month = `${reportDate.getMonth() + 1}`.padStart(2, '0')

          Download(blob, `${item.partner}_${reportDate.getFullYear()}-${month}.xlsx`)
        } catch (e) {
          AppNotifications.error(e)
        }
      },
      visible: item => item.filePath !== null
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.export = function (id) {
        return this.get(Model.$routes.commissions.export(id), { save: false, responseType: 'blob' })
      }
      configActions.markAsSend = function (id) {
        return this.put(Model.$routes.commissions.markAsSend(id), { dataKey: null })
      }

      return configActions
    }
  }
}
