import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'

import helpers from '~/plugins/vuex-orm/plugins/helpers'
import ormTable from '~/plugins/vuex-orm/plugins/orm-table'
import queries from '~/plugins/vuex-orm/plugins/queries'
import VuexORMAxiosConfig from '~/plugins/vuex-orm/plugins/vuex-orm-axios-config'

import { actions } from '~/const/global'

// TODO: create automatic import of models.
import Organization from '~/models/directories/Organization'
import User from '~/models/administration/User'
import Role from '~/models/administration/Role'
import Permission from '~/models/auth/Permission'
import Group from '~/models/auth/Group'
import MediaObject from '~/models/system/MediaObject'
import Dialog from '~/models/system/Dialog'
import OrganizationManagers from '~/models/directories/pivot/OrganizationManagers'
import AuthToken from '~/modules/auth/Models/AuthToken'
import CurrentUser from '~/modules/auth/Models/CurrentUser'
import Sidebar from '~/modules/sidebar/models/Sidebar'
import Notifications from '~/modules/notifications/models/Notifications'
import Partners from '~/modules/partners/models/Partners'
import OrganizationIntegration from '~/models/directories/pivot/OrganizationIntegration'
import Billing from '~/models/billing/Billing'
import Period from '~/models/billing/Period'
import Debt from '~/models/billing/Debt'
import DebtItems from '~/models/billing/DebtItems'
import Invoice from '~/models/billing/Invoice'
import Payment from '~/models/billing/Payment'
import Commission from '~/models/partner/Commission'
import Subscription from '~/modules/dashboard/models/Subscription'
import ClientBalance from '~/models/directories/ClientBalance'
import CommissionItems from '~/models/partner/CommissionItems'
import ClientIntegration from '~/models/integrations/ClientIntegration'
import DistributedPayment from '~/models/billing/DistributedPayment'
import ClientOrganizationIntegration from '~/models/integrations/ClientOrganizationIntegration'

export const models = {
  User,
  Role,
  Permission,
  Group,
  MediaObject,
  Organization,
  Dialog,
  OrganizationManagers,
  AuthToken,
  CurrentUser,
  Notifications,
  Sidebar,
  Partners,
  OrganizationIntegration,
  Billing,
  Period,
  Debt,
  DebtItems,
  Invoice,
  Payment,
  Commission,
  Subscription,
  ClientBalance,
  CommissionItems,
  ClientIntegration,
  DistributedPayment,
  ClientOrganizationIntegration
}

export const mapping = {
  User,
  Role,
  Permission,
  Group,
  MediaObject,
  Organization
}

function forEach (callback) {
  for (const index in models) {
    const concrete = models[index]

    callback(concrete)
  }
}

export function mapAbilities (rules, config, mock = true) {
  if (mock) {
    return mockAbilities(config)
  }

  return rules
    ? rules.map((item) => {
      if (!(item.subject in mapping)) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(['You need create mapping for:', item.subject].join(' ').trim(), 'entity!')
        }

        return {}
      }

      return Object.assign({}, item, { subject: mapping[item.subject][config.modelKey] })
    })
    : []
}

export function mockAbilities (config) {
  const abilities = []

  forEach((model) => {
    abilities.push({ actions: Object.values(actions), subject: model[config.modelKey] })
  })

  return abilities
}

export function vuexORMPlugin (store) {
  VuexORM.use(VuexORMAxios) // <- No axios option.
  VuexORM.use(VuexORMAxiosConfig)
  VuexORM.use(helpers)
  VuexORM.use(ormTable)
  VuexORM.use(queries)

  const database = new VuexORM.Database()

  forEach(model => database.register(model))

  return VuexORM.install(database)
}
