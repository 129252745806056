import OrmModel from '~/models/abstracts/base/OrmModel'
export default class Subscription extends OrmModel {
  static entity = 'subscription'
  static fields () {
    return {
      id: this.attr(null),
      type: this.attr(null),
      active: this.attr(null)
    }
  }
}
