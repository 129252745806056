<template lang="pug">
  div(:class="{ 'support-buttons': true, 'mobile': mobile, 'alternative-mobile': alternativeMobile }")
    template(v-if="!hidePhoneNumber")
      a(
        v-if="!showPhoneBtn"
        :href="telLink"
        class="support-buttons__link"
        :class="{'support-buttons__link--centered': alternativeMobile}"
        target="_blank"
      ) тел. {{ tel }}
    v-btn(
      v-for="(btn, key) in supportButtons"
      :key="key"
      @click="callAction(btn.call)"
      class="support-buttons__btn"
      depressed
      v-if="btn.visible"
      :id="btn.id"
      v-bind="$attrs"
    )
      e-svg-icon(:size="iconSize || 'md'") {{ btn.icon }}
    a(
      v-if="alternativeMobile"
      :href="telLink"
      class="main-button outlined order-2 mt-3 mb-8 w-100 d-flex align-center justify-center call-btn"
      target="_blank"
    ) {{ $t('Call') }}
</template>

<script>
import responsive from '~/mixins/pages/responsive'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import contentDialog from '~/mixins/dialogs/contentDialog'

export default {
  components: {
    ESvgIcon
  },
  mixins: [responsive, contentDialog],
  props: {
    showPhoneBtn: {
      type: Boolean,
      default: false
    },
    hidePhoneNumber: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    },
    alternativeMobile: {
      type: Boolean,
      default: false
    },
    iconSize: {
      type: String,
      default: ''
    },
    secondary: {
      type: Boolean,
      default: false
    },
    shouldLoadHelpDesk: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    tel: '0 800 331 891',
    showChatWidget: false
  }),
  computed: {
    telLink () {
      return 'tel:+38' + this.tel.replace(/\s/g, '')
    },
    supportButtons () {
      return [
        {
          icon: this.mobile || this.secondary ? 'chat-2' : 'chat',
          id: 'support-chat',
          call: this.callSupportChat,
          visible: !this.hidePhoneNumber
        },
        {
          icon: this.mobile || this.secondary ? 'telegram-2' : 'telegram',
          call: () => this.openLink('https://t.me/navkoloone_bot'),
          visible: true
        },
        {
          icon: 'instagram',
          call: () => this.openLink('https://www.instagram.com/navkolo.one/'),
          visible: this.hidePhoneNumber
        },
        {
          icon: this.mobile || this.secondary ? 'viber-2' : 'viber',
          call: () => this.openLink('viber://pa?chatURI=navkoloone_bot'),
          visible: !this.hidePhoneNumber
        },
        {
          icon: 'facebook',
          call: () => this.openLink('https://www.facebook.com/navkolo.one'),
          visible: this.hidePhoneNumber
        },
        {
          icon: this.secondary ? 'phone-2' : 'phone',
          call: () => this.openLink(this.telLink),
          visible: this.showPhoneBtn
        }
      ]
    }
  },
  mounted () {
    if (this.shouldLoadHelpDesk) {
      this.helpdeskeddyInit()
    }
  },
  methods: {
    helpdeskeddyInit () {
      if (this.$User?.isSuperAdmin) {
        return
      }
      const docScript = document.querySelector('#hde-chat-widget')

      if (docScript) {
        if (docScript.previousSibling.tagName === 'STYLE') {
          docScript.previousSibling.remove()
        }
        const chat = document.querySelector('#hde-container')
        if (chat) {
          chat.remove()
        }
        docScript.remove()
      }

      const script = document.createElement('script')
      script.src = '//cdn.helpdeskeddy.com/js/modules/chat_widget/hde.js'
      script.id = 'hde-chat-widget'
      script.setAttribute('data-lang', 'ua')
      script.setAttribute('data-host', 'support.navkolo.one')
      script.setAttribute('data-custom-button', 'support-chat')
      script.setAttribute('data-user-name', `${this._.get(this.$User, 'profile.name ', '')}`)
      script.setAttribute('data-user-email', this._.get(this.$User, 'profile.email', ''))
      document.body.appendChild(script)
    },
    callSupportChat () {
      this.showChatWidget = true
    },
    openLink (url) {
      window.open(url, '_blank')
    },
    callAction (action) {
      if (this._.isFunction(action)) {
        action()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.support-buttons {
  display: flex;
  align-items: center;
  gap: 5px;

  &__link {
    color: rgba(0, 0, 0, 0.6) !important;
    text-align: center;
    font-size: .8rem;
    text-decoration: none;
    margin-right: 7px;

    &--centered {
      order: 1;
      width: 100%;
      padding: 20px 0 10px;
    }
  }

  &__btn {
    color: $dark;
    width: 40px !important;
    height: 40px !important;
  }

  &.mobile {
    flex-wrap: wrap;
    gap: 20px;

    .support-buttons {
      &__link {
        order: 1;
        margin-right: 0;
        width: 100%;
        display: block;
        text-align: left;
        color: rgba(255, 255, 255, 0.5) !important;
        font-size: 13px;
      }

      &__btn {
        background-color: rgba(240, 241, 242, 0.2) !important;
      }
    }
  }
  &.alternative-mobile {
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 40px;
  }

  .call-btn {
    border-radius: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none !important;
  }
}
</style>
