<template lang="pug">
  v-row(justify="center" )
    v-col( cols="6")
      v-text-field(
        v-model="organizationName"
        :label="$t('Contr agent name')"
        :filled="true"
      )
    v-col(cols="6")
      v-text-field(
        v-model="userEmail",
        :label="$t('Receiver email')",
        :filled="true"
      )
    v-col(cols="12")
      v-data-table(
        v-model="selectedDebtItems"
        :headers="tableHeaders",
        :items="debtItems"
        :loading="itemsLoading"
        item-key="id"
        show-select
      )
    v-col(cols="auto")
      e-button-main(borderRadius="16" :loading="createLoading" @click="createInvoice")
        | Create invoice

</template>

<script>
import DebtItems from '~/models/billing/DebtItems'
import Invoice from '~/models/billing/Invoice'
import EButtonMain from '~/components/elements/buttons/e-button-main'
import { apiPrefix } from '~/const/routes'
export default {
  components: { EButtonMain },
  props: {
    debt: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    createLoading: false,
    itemsLoading: false,
    organizationName: null,
    userEmail: null,
    selectedDebtItems: [],
    tableHeaders: [
      {
        text: 'Partner',
        align: 'start',
        sortable: false,
        value: 'provider'
      },
      {
        text: 'Type',
        align: 'start',
        sortable: false,
        value: 'type'
      },
      {
        text: 'Count',
        align: 'start',
        sortable: false,
        value: 'count'
      },
      {
        text: 'Price',
        align: 'start',
        sortable: false,
        value: 'price'
      },
      {
        text: 'Organization',
        align: 'start',
        sortable: false,
        value: 'organizationString'
      }
    ]
  }),
  computed: {
    debtItems () {
      return DebtItems.query().where('invoice', null).withAll(1).get()
    }
  },
  created () {
    this.itemsLoading = true
    this.getDebtItems()
    this.itemsLoading = false
  },
  methods: {
    getDebtItems () {
      return DebtItems.api().filter({ 'debt.id': this.debt.id, 'exists[invoice]': false }).all()
    },
    async createInvoice () {
      if (!this.selectedDebtItems.length || !this.userEmail || !this.organizationName) {
        this.$notification.error('Заповніть всі поля')
      } else {
        const payload = {
          debt: apiPrefix + '/debts/' + this.debt.id,
          debtItems: this.selectedDebtItems.map(item => apiPrefix + '/debt_items/' + item.id),
          organizationName: this.organizationName,
          receiverEmail: this.userEmail
        }
        try {
          this.createLoading = true
          await Invoice.api().create(payload)
          this.$notification.success('Рахунок створено')
          this.$attrs.closeModal(true)
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.createLoading = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
