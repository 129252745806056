<template lang="pug">
  v-card(class="connect-form")
    v-row(justify="center" align="center" no-gutters)
      v-col(cols="12")
        v-card-title(class="d-flex flex-column align-start")
          h1(class='form-title') {{ $t('Product connection') }}
          p(v-if="organization.name" class='form-text') {{ $t('For organization') }}
            span(class="font-weight-bold")
              |
              | {{ organization.name }}
        v-card-text
          form(id="manual_setup")
            ValidationObserver(ref="form")
              t-orm-fields(v-bind.sync="dataForm" :items="fields")
              v-col(v-if="kvedVisibility" cols="12" class="pa-0")
                ValidationProvider(:name="$t('code KVED')" vid="kved" rules="required" v-slot="{ errors }")
                  v-autocomplete(
                    v-model="dataForm.kved"
                    :items="formattedKveds"
                    item-text="name"
                    :label="dataForm.kved.length ? '' : $t('KVED (field from Tax)')"
                    :placeholder="isAutocompleteFocused ? $t('Search by KVED codes') : ''"
                    :error-messages="errors"
                    :menu-props="{ maxWidth: 625, bottom: true, offsetY: true, offsetOverflow: false, rounded: true }"
                    :filter="autocompleteFilter"
                    @focus="isAutocompleteFocused = !isAutocompleteFocused"
                    @blur="isAutocompleteFocused = !isAutocompleteFocused"
                    chips
                    small-chips
                    deletable-chips
                    dense
                    filled
                    multiple
                    return-object
                    clearable
                    :allow-overflow="false"
                  )
                    template(v-slot:selection="data")
                      v-chip(
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click:close="removeChip(data.item)"
                      ) {{$t('KVED')}}-{{ data.item.code }}
                    template(v-slot:item="data")
                      v-list-item-content
                        div(class="d-flex align-center")
                          span(class="mr-6 label-kved") {{ data.item.code }}
                          span {{ data.item.name }}

              p(
                v-if="bankFieldsVisibility"
                class="form-text mb-4 mt-8"
              ) {{ $t('Bank account') }}
              t-orm-fields(
                v-if="bankFieldsVisibility"
                v-bind.sync="bankForm"
                :items="bankFields"
              )
              template(v-if="dataForm.outletsData.length")
                template(v-for="(o,idx) in dataForm.outletsData")
                  div(class="d-flex align-center justify-space-between mb-3")
                    p(class="form-text mb-0") {{ _.upperFirst($t('outlet')) }} {{ idx + 1 }}
                    e-button-main(
                      plain
                      text-color="danger"
                      @click="removeOutlet"
                      class="btn-danger"
                    )
                      e-svg-icon(name="danger-trash" class="mr-2")
                      | {{ $t('Delete') }}
                  t-orm-fields(v-bind.sync="dataForm.outletsData[idx]" :items="outletFields")
              e-button-main(
                v-if="outletsVisibility"
                plain
                text-color="primary"
                @click="addOutlet"
              )
                e-svg-icon(name="primary-plus" class="mr-2")
                | {{ $t('Add outlet') }}
              v-row(class="mt-10" align="center" justify="space-between")
                v-col(cols="6")
                  p(class="form-text form-text--gray mb-0") {{ $t('Fields required') }}
                v-col(cols="6")
                  e-button-main(id="manual_setup" borderRadius="16" width="100%" :loading="isLoading" @click="submit") {{ $t('Save') }}
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import responsive from '~/mixins/pages/responsive'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import ELink from '~/components/elements/links/e-link'
import EButtonMain from '~/components/elements/buttons/e-button-main'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Organization from '~/models/directories/Organization'
import kveds from '~/const/kved.json'
import addOrgAutomaticEds from '~/mixins/addOrgAutomaticEds'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    TOrmFields,
    ELink,
    EButtonMain,
    ESvgIcon
  },
  mixins: [responsive, addOrgAutomaticEds],
  props: {
    organization: {
      type: Object,
      default: () => ({})
    },
    isCreateOrganization: {
      type: Boolean,
      default: false
    },
    isFormOpenedInAnotherComponent: {
      type: Boolean,
      default: false
    },
    showFullForm: {
      type: Boolean,
      default: true
    },
    partnerName: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dataForm: {
      name: '',
      edrpou: '',
      personType: null,
      director: null,
      taxationType: null,
      vatCode: null,
      taxCode: null,
      kved: [],
      outletsData: [],
      bankAccounts: [],
      cashRegistersData: []
    },
    bankForm: {
      bank_title: null,
      bank_mfo: null,
      number: null,
      currency_iso: '980',
      default_acc: 0
    },
    isLoading: false,
    outlet: {
      name: null,
      address: null
    },
    isAutocompleteFocused: false
  }),
  computed: {
    fields () {
      return [
        {
          model: 'name',
          component: 'v-text-field',
          provider: {
            name: 'Organization full name',
            vid: 'name',
            rules: 'required'
          },
          attrs: {
            label: 'Organization full name required',
            required: true,
            filled: true,
            visible: this.isCreateOrganization
          },
          cols: {
            cols: 12
          }
        },
        {
          model: 'edrpou',
          component: 'v-text-field',
          provider: {
            name: 'Edrpou',
            vid: 'edrpou',
            mode: 'aggressive',
            rules: 'required|numeric|max:10'
          },
          attrs: {
            label: 'Edrpou/IPN/RNOKPP required',
            required: true,
            filled: true,
            type: 'number',
            visible: this.checkFieldVisibility('edrpou')
          },
          fieldVal: () => this.organization.edrpou ? this.organization.edrpou : '',
          cols: {
            cols: ['dnTrade', 'hugeProfit'].includes(this.partnerName) ? 12 : 6
          }
        },
        {
          model: 'personType',
          component: 'v-select',
          provider: {
            name: 'Company type',
            vid: 'personType',
            rules: 'required'
          },
          attrs: {
            label: 'Company type required',
            required: true,
            filled: true,
            menuProps: { bottom: true, offsetY: true },
            visible: this.checkFieldVisibility('personType')
          },
          items: () => Organization.personTypes,
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'director',
          component: 'v-text-field',
          provider: {
            name: 'Director full name',
            vid: 'director',
            rules: { required_if: this.dataForm.personType === 'juridical' }
          },
          attrs: {
            label: 'Director full name required',
            required: true,
            filled: true,
            visible: this.dataForm.personType === 'juridical' || this.partnerName === 'dnTrade'
          },
          fieldVal: () => this.directorName ? this.directorName : '',
          cols: {
            cols: 12
          }
        },
        {
          model: 'taxationType',
          component: 'v-select',
          provider: {
            name: 'Taxation type',
            vid: 'taxationType',
            rules: 'required'
          },
          attrs: {
            label: 'Taxation type required',
            required: true,
            filled: true,
            menuProps: { bottom: true, offsetY: true },
            visible: this.checkFieldVisibility('taxationType')
          },
          items: () => [{ text: 'Платник єдиного податку', value: 'single_tax' }, { text: 'Загальний податок', value: 'general' }],
          cols: {
            cols: 12
          }
        },
        {
          model: 'vatCode',
          component: 'v-text-field',
          provider: {
            name: 'VAT code',
            vid: 'vatCode',
            rules: 'numeric'
          },
          attrs: {
            label: 'VAT code',
            filled: true,
            type: 'number',
            visible: this.checkFieldVisibility('vatCode')
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'taxCode',
          component: 'v-text-field',
          provider: {
            name: 'Tax code',
            vid: 'taxCode',
            mode: 'aggressive',
            rules: 'required|numeric|max:4'
          },
          attrs: {
            label: 'Tax code (where registered)',
            filled: true,
            type: 'number',
            visible: this.checkFieldVisibility('taxCode')
          },
          cols: {
            cols: 12,
            md: 6
          }
        }
      ]
    },
    bankFields () {
      return [
        {
          model: 'bank_title',
          component: 'v-text-field',
          provider: {
            name: 'Bank name',
            vid: 'bankTitle',
            rules: { min: 3, required_if: !!this.bankForm.bank_mfo || !!this.bankForm.number }
          },
          attrs: {
            label: 'Bank name',
            filled: true
          },
          cols: {
            cols: 12
          }
        },
        {
          model: 'bank_mfo',
          component: 'v-text-field',
          provider: {
            name: 'MFO of bank',
            vid: 'bankMfo',
            rules: { numeric: true, length: 6, required_if: !!this.bankForm.bank_title || !!this.bankForm.number }
          },
          attrs: {
            label: 'MFO of bank',
            filled: true,
            type: 'number'
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'number',
          component: 'v-text-field',
          provider: {
            name: 'IBAN',
            vid: 'iban',
            rules: { iban: true, required_if: !!this.bankForm.bank_title || !!this.bankForm.bank_mfo }
          },
          attrs: {
            label: 'IBAN (hryvnia account)',
            filled: true
          },
          cols: {
            cols: 12,
            md: 6
          }
        }
      ]
    },
    outletFields () {
      return [
        {
          model: 'name',
          component: 'v-text-field',
          provider: {
            name: 'Outlet name or warehouse',
            vid: 'outletName',
            rules: { min: 3, required_if: this.dataForm.outletsData[this.dataForm.outletsData.length - 1].address !== '' }
          },
          attrs: {
            label: 'Outlet name or warehouse',
            filled: true
          },
          cols: {
            cols: 12
          }
        },
        {
          model: 'address',
          component: 'v-text-field',
          provider: {
            name: 'Address',
            vid: 'outletAddress',
            rules: { min: 3, required_if: this.dataForm.outletsData[this.dataForm.outletsData.length - 1].name !== '' }
          },
          attrs: {
            label: 'Address',
            filled: true
          },
          cols: {
            cols: 12
          }
        }
      ]
    },
    formattedKveds () {
      // TODO move this to server
      const sections = kveds.sections[0].map(section => section.divisions)
      const groups = sections.map((divisions) => {
        const division = divisions.map(division => division.groups)
        return division.map((groups) => {
          const classes = groups.map(group => group.classes)
          return classes.map(classes => classes.map(clas => ({ name: clas.className, code: clas.classCode, isMain: true })))
        })
      })
      return [...this._.flattenDeep(groups)]
    },
    fieldsVisibilityRules () {
      return {
        dnTrade: {
          name: true,
          edrpou: true,
          director: true
        },
        bookKeeper: {
          name: true,
          edrpou: true,
          personType: true,
          director: true,
          taxationType: true,
          vatCode: true,
          taxCode: true,
          kved: true,
          outletsData: true,
          bankAccounts: true,
          cashRegistersData: true,
          bankFields: true,
          outlets: true
        },
        hugeProfit: {
          name: true,
          edrpou: true
        },
        masterBuh: {
          name: true,
          edrpou: true,
          personType: true,
          director: true,
          taxationType: true,
          vatCode: true,
          taxCode: true,
          kved: true,
          outletsData: true,
          bankAccounts: true,
          cashRegistersData: true,
          bankFields: true,
          outlets: true
        }
      }
    },
    bankFieldsVisibility () {
      if (this.showFullForm) {
        return true
      }

      return !!this.fieldsVisibilityRules[this.partnerName]?.bankFields
    },
    kvedVisibility () {
      if (this.showFullForm) {
        return true
      }

      return !!this.fieldsVisibilityRules[this.partnerName]?.kved
    },
    outletsVisibility () {
      if (this.showFullForm) {
        return true
      }

      return !!this.fieldsVisibilityRules[this.partnerName]?.outlets
    },
    directorName () {
      return this._.get(this.$User, 'profile.name', '')
    }
  },
  methods: {
    addOutlet () {
      if (this.dataForm.outletsData.length) {
        this.outlet = { name: '', address: '' }
      }
      this.dataForm.outletsData.push(this.outlet)
    },
    removeOutlet (index) {
      this.dataForm.outletsData.splice(index, 1)
    },
    removeChip (item) {
      const removeIdx = this.dataForm.kved.findIndex(kved => kved.code === item.code)
      this.dataForm.kved.splice(removeIdx, 1)
    },
    autocompleteFilter (item, queryText) {
      return (
        item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) ||
        item.code.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
      )
    },
    checkFieldVisibility (field) {
      if (this.showFullForm) {
        return true
      }

      return !!this.fieldsVisibilityRules[this.partnerName][field]
    },
    async submit () {
      const valid = await this.$refs.form.validate()

      if (valid) {
        this.isLoading = true
        try {
          const payload = {
            edrpou: this.dataForm.edrpou,
            personType: this.dataForm.personType,
            info: { outletsData: this.dataForm.outletsData, taxationType: this.dataForm.taxationType, taxCode: this.dataForm.taxCode, vatCode: this.dataForm.vatCode, kved: this.dataForm.kved, cashRegistersData: [], bankAccounts: [] }
          }
          if (this.dataForm.name) {
            payload.name = this.dataForm.name
          }
          if (this.dataForm.director) {
            payload.info.director = this.dataForm.director
          }

          this.isCreateOrganization ? await Organization.api().addManually(payload) : await Organization.api().update(this.organization, payload)
          this.$notification.success(this.$t('Organization data successfully set'))

          if (this.isFormOpenedInAnotherComponent) {
            this.$emit('close')
          } else {
            return this.$attrs.closeModal(true)
          }
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.isLoading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.connect-form {
  width: 100%;
  box-shadow: none;
}
.form-title {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: $dark;
}
.form-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(7, 6, 0, 0.7);

  &--gray {
    color: rgba(7, 6, 0, 0.5);
  }
}
.label-kved {
  color: $primary-light;
}

.v-chip {
  background-color: rgba(0, 187, 249, 0.2) !important;
}
</style>
