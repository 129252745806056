import { Model } from '@vuex-orm/core'
import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import Debt from '~/models/billing/Debt'
import Dialog from '~/models/system/Dialog'
import DebtItems from '~/models/billing/DebtItems'
import Invoice from '~/models/billing/Invoice'
import Billing from '~/models/billing/Billing'
import { DateTime } from '~/services/_utils/DateTime'

export default class Period extends ChainInheritance(Administration, [
  SoftDeletAble,
  TimestampAble,
  PersistAble
]) {
  static entity = 'periods'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'startDate'
  static defaultSortOrder = true

  static fields () {
    return super.fields({
      debt_id: this.attr(null),
      billing_id: this.attr(null),

      id: this.attr(null),
      startDate: this.attr(null),
      endDate: this.attr(null),
      isTrial: this.attr(null),
      debt: this.belongsTo(Debt, 'debt_id'),
      billing: this.belongsTo(Billing, 'billing_id')
    })
  }

  get startDateString () {
    return DateTime.format(this.startDate)
  }

  get endDateString () {
    return DateTime.format(this.endDate)
  }

  get debtStatus () {
    return this._.get(this, 'debt.status', '')
  }

  get debtSum () {
    const sum = this._.get(this, 'debt.sum', '')
    return sum ? sum / 100 : 0
  }

  get trial () {
    return this.isTrial ? 'Так' : 'Ні'
  }

  get userId () {
    return this.billing?.user.id
  }

  get organizationNames () {
    const orgs = this.billing?.user.organizations
    if (Array.isArray(orgs)) {
      let orgNames = ''
      orgs.forEach((item) => {
        orgNames += item.name + ' '
      })
      return orgNames
    }
    return ''
  }

  get payerCode () {
    return '#' + this._.get(this, 'billing.payerCode', '')
  }

  get email () {
    return this._.get(this, 'billing.user.login', '')
  }

  static ormTrans = {
    single: 'Service period',
    multy: 'Service periods'
  }

  static ormHeaders = [
    { text: 'Start date', value: 'startDateString', sortable: false, filterable: false },
    { text: 'User id', value: 'userId', sortable: false, filterable: false },
    { text: 'Payer code', value: 'payerCode', sortable: false, filterable: false },
    { text: 'Email', value: 'email', sortable: false, filterable: false },
    { text: 'Organizations', value: 'organizationNames', sortable: false, filterable: false },
    { text: 'Debt sum', value: 'debtSum', sortable: false, filterable: false },
    { text: 'Status', value: 'debtStatus', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormFilters = [
    {
      model: 'startDate',
      type: 'dateRange',
      component: 'e-input-datetime-range',
      attrs: {
        closeOnClick: true,
        clearable: true,
        emmitOnlyOnSelectClicked: false,
        type: 'date',
        range: true,
        'hide-details': true,
        outlined: true,
        label: 'Start date'
      }
    },
    {
      model: 'debt.status',
      component: 'v-select',
      provider: {
        name: 'Status',
        vid: 'status',
        rules: ''
      },
      attrs: {
        label: 'Status',
        outlined: true,
        clearable: true,
        'return-object': false
      },
      items: () => [Debt.STATUS_NEW, Debt.STATUS_PAYED, Debt.STATUS_NOT_PAYED, Debt.STATUS_FREE]
    },
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 4
              },
              fields: [
                'startDate'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 4
              },
              fields: [
                'debt.status'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 4
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = [
    {
      name: 'consumptions',
      text: 'Show consumptions',
      visible: item => item.debt,
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          title: 'Consumptions',
          component: 'm-orm-table',
          width: 'full',
          componentProps: {
            model: DebtItems,
            customRequest: ({ model, requestParams }) => {
              return model.api().filter({ debt: item.debt }).all({ persistBy: 'create' })
            }
          }
        })
      }
    },
    {
      name: 'createInvoice',
      text: 'Create invoice',
      visible: item => false,
      // visible: item => item.debt,
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          title: 'Create invoice',
          component: 'm-form-create-invoice',
          width: '800px',
          componentProps: {
            debt: item.debt
          }
        })
      }
    },
    {
      name: 'showInvoice',
      text: 'Show Invoices',
      visible: item => item.debt,
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          title: 'Invoices',
          component: 'm-orm-table',
          width: '1000px',
          componentProps: {
            model: Invoice,
            customRequest: ({ model, requestParams }) => {
              return model.api().filter({ debt: item.debt }).all({
                persistBy: 'create'
              })
            }
          }
        })
      }
    },
    {
      name: 'cancelPeriod',
      text: 'Cancel period',
      visible: item => item.debt,
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()

        await dialog.open({
          width: '600px',
          title: 'Cancel period?',
          component: 'm-form-block',
          componentProps: {
            fields: [],
            buttonText: 'Ok',
            successMessage: 'Period was cancelled',
            onSubmit: async () => {
              const url = Model.$routes[Debt.entity].concrete(item.debt.id)
              const payload = { status: 'free' }

              await Debt.api().put(url, payload, { dataKey: null })
              await dialog.close()
            }
          }
        })
      }
    }
  ]

  static ormDialogs = {
  }

  static ormDialogsConfig = {

  }

  // static apiConfig = {
  //   get actions () {
  //     const configActions = Object.assign({}, Model.apiConfig.actions)
  //     configActions.addManagedOrganization = function (id, payload) {
  //       return this.put(
  //         Model.$routes.users.addManagedOrganization(id),
  //         payload
  //       )
  //     }
  //     configActions.getCurrent = function () {
  //       return this.get(Model.$routes.users.profile(), {
  //         dataKey: null,
  //         save: true,
  //         persistBy: 'create',
  //         persistOptions: {
  //           insertOrUpdate: ['organization']
  //         }
  //       })
  //     }
  //     return configActions
  //   }
  // }
}
