import { Model } from '@vuex-orm/core'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import PersistAble from '~/models/mixins/PersistAble'
import Directory from '~/models/abstracts/Directory'

export default class Partners extends ChainInheritance(Directory, [
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'partners'
  static paginated = true

  static keys = {
    dnTrade: 'dn_trade',
    masterBuh: 'master_buh',
    bookKeeper: 'book_keeper',
    hugeProfit: 'huge_profit'
  }

  static fields () {
    return {}
  }

  static ormHeaders = []

  static ormFilters = []

  static ormFiltersConfig = {}

  static ormActions = []

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.partnerCheckSupport = function (id, partnerName) {
        return this.get(Model.$routes.partners.partnerCheckSupport(id, partnerName), { save: false })
      }
      configActions.partnerRegistration = function (partnerName, payload) {
        return this.post(Model.$routes.partners.partnerRegistration(partnerName), payload, { save: false })
      }
      configActions.addOrganizationToPartner = function (partnerName, id) {
        return this.put(Model.$routes.partners.addOrganizationToPartner(partnerName, id), {}, { save: false })
      }
      configActions.partnerRegistrationStatusCheck = function (partnerName, id) {
        return this.get(Model.$routes.partners.partnerRegistrationStatusCheck(partnerName, id), { save: false })
      }
      configActions.addOrganizationStatusCheck = function (partnerName, id) {
        return this.get(Model.$routes.partners.addOrganizationStatusCheck(partnerName, id), { save: false })
      }
      configActions.partnerGetSuccessRegistration = function (partnerName) {
        return this.get(Model.$routes.partners.partnerGetSuccessRegistration(partnerName), { save: false })
      }
      configActions.partnerRecreateAuthLink = function (partnerName) {
        return this.put(Model.$routes.partners.partnerRecreateAuthLink(partnerName), {}, { save: false })
      }

      return configActions
    }
  }
}
