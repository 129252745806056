import { render, staticRenderFns } from "./m-block-set-organization.vue?vue&type=template&id=e41db99c&scoped=true&lang=pug&"
import script from "./m-block-set-organization.vue?vue&type=script&lang=js&"
export * from "./m-block-set-organization.vue?vue&type=script&lang=js&"
import style0 from "./m-block-set-organization.vue?vue&type=style&index=0&id=e41db99c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e41db99c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCheckbox,VCol,VRow,VTextField})
