export const menuItems = [
  {
    text: 'Home page',
    href: '/dashboard',
    visible: user => !user?.isSuperAdmin
  },
  {
    text: 'My organizations',
    href: '/dashboard/myorganizations',
    visible: user => !user?.isSuperAdmin
  },
  {
    text: 'Payments',
    href: '/payments',
    visible: user => !user?.isSuperAdmin
  },
  {
    text: 'Users',
    icon: {
      type: 'e-svg-icon',
      text: 'users',
      options: {
        color: '#a5a4bf',
        size: 'lg'
      }
    },
    href: '/admin/users',
    visible: user => user?.isSuperAdmin
  },
  {
    text: 'Organizations',
    icon: {
      type: 'e-svg-icon',
      text: 'outlets',
      options: {
        color: '#a5a4bf',
        size: 'lg'
      }
    },
    href: '/admin/organizations',
    visible: user => user?.isSuperAdmin
  },
  {
    text: 'Billing',
    icon: {
      type: 'e-svg-icon',
      text: 'billing',
      options: {
        color: '#a5a4bf',
        size: 'lg'
      }
    },
    href: '/admin/billing',
    visible: user => user?.isSuperAdmin
  },
  {
    text: 'Payment',
    icon: {
      type: 'e-svg-icon',
      text: 'money',
      options: {
        color: '#a5a4bf',
        size: 'lg'
      }
    },
    href: '/admin/payment',
    visible: user => user?.isSuperAdmin
  },
  {
    text: 'Periods',
    icon: {
      type: 'e-svg-icon',
      text: 'book',
      options: {
        color: '#a5a4bf',
        size: 'lg'
      }
    },
    href: '/admin/periods',
    visible: user => user?.isSuperAdmin
  },
  {
    text: 'Partner commissions',
    icon: {
      type: 'e-svg-icon',
      text: 'money',
      options: {
        color: '#a5a4bf',
        size: 'lg'
      }
    },
    href: '/admin/commissions',
    visible: user => user?.isSuperAdmin
  },
  {
    text: 'Client balances',
    icon: {
      type: 'e-svg-icon',
      text: 'money',
      options: {
        color: '#a5a4bf',
        size: 'lg'
      }
    },
    href: '/admin/client-balances',
    visible: user => user?.isSuperAdmin
  },
  {
    text: 'Client integrations',
    icon: {
      type: 'e-svg-icon',
      text: 'shopping-cart',
      options: {
        color: '#a5a4bf',
        size: 'lg'
      }
    },
    href: '/admin/client-integrations',
    visible: user => user?.isSuperAdmin
  }
]
