<template lang="pug">
  v-form(class="pa-5")
    h1(class="form-title") {{ $t('Select organizations') }}
    p(class="form-text") {{ $t('Select organizations to connect and press continue') }}
    ValidationObserver(ref="form")
      template(v-for="organization in organizations")
        v-checkbox(
          v-model="selected[organization.id]"
          :label="organization.name"
          class="mt-0"
        )
      v-row(class="pt-3")
        v-col(cols="6" offset="6")
          v-btn(
            class="main-button w-100"
            @click="select"
          ) {{ $t('Continue') }}
</template>

<script>

export default {
  props: {
    organizations: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    selected: []
  }),
  computed: {},
  created () {
    this.organizations.forEach((item) => {
      this.selected[item.id] = false
    })
  },
  methods: {
    select () {
      const selectedOrg = []
      this.organizations.forEach((item) => {
        if (this.selected[item.id]) {
          selectedOrg.push(item)
        }
      })
      this.$attrs.closeModal(selectedOrg)
    }
  }
}
</script>

<style lang="scss" scoped>

.form-title {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
}

.form-text {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(7, 6, 0, 0.7);
}
</style>
