import { Model } from '@vuex-orm/core'
import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import AssignAble from '~/models/mixins/AssignAble'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import User from '~/models/administration/User'
// import Stepper from '~/view_configs/modules/stepper/Stepper'
import OrganizationIntegration from '~/models/directories/pivot/OrganizationIntegration'

export default class Organization extends ChainInheritance(Administration, [
  SoftDeletAble,
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'organization'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'name'
  static defaultSortOrder = false
  static persistOptions = {
    insertOrUpdate: ['users']
  }

  static personTypes = [
    {
      text: 'Фізична особа підприємець',
      value: 'individual'
    }, {
      text: 'Юридична особа',
      value: 'juridical'
    }
  ]

  static fields () {
    return super.fields({
      owner_id: this.attr(null),

      id: this.attr(null),
      name: this.attr(null),
      shortName: this.attr(null),
      edrpou: this.attr(null),
      owner: this.belongsTo(User, 'owner_id'),
      personType: this.attr(null),
      organizationIntegration: this.hasMany(OrganizationIntegration, 'organizationId')
    })
  }

  static ormRelationMap = [
    'owner'
  ]

  static ormFieldsMap = {}

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormTrans = {
    single: 'Organization',
    multy: 'Organizations',
    dialog: 'organization'
  }

  get ownerFullName () {
    return `${this._.get(this.owner, 'profile.firstName', '')} ${this._.get(this.owner, 'profile.lastName', '')}`
  }

  get billingPayerCode () {
    return '#' + this._.get(this.owner, 'billing.payerCode')
  }

  get phone () {
    return this._.get(this.owner, 'profile.phone', '-')
  }

  get email () {
    return this._.get(this.owner, 'profile.email', '-')
  }

  static ormHeaders = [
    { text: 'Name', value: 'name', sortable: true, filterable: true },
    { text: 'Phone', value: 'phone', sortable: false, filterable: false },
    { text: 'Email', value: 'email', sortable: false, filterable: false },
    { text: 'Edrpou', value: 'edrpou', sortable: true, filterable: true },
    { text: 'Payer code', value: 'billingPayerCode', sortable: true, filterable: true },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['active'],
      'sort-desc': [true]
    })
  }

  static ormActions = [
    {
      name: 'login'
    },
    {
      name: 'read',
      text: 'View',
      icon: {
        text: 'info',
        type: 'e-svg-icon'
      }
    }
  ]

  static ormFields = [
    {
      model: 'name',
      component: 'v-text-field',
      provider: {
        vid: 'name',
        name: 'Name',
        rules: 'required'
      },
      attrs: {
        label: 'Name',
        outlined: true
      }
    },
    {
      model: 'shortName',
      component: 'v-text-field',
      provider: {
        vid: 'shortName',
        name: 'Short name',
        rules: 'required'
      },
      attrs: {
        label: 'Short name',
        outlined: true
      }
    },
    {
      model: 'edrpou',
      component: 'v-text-field',
      provider: {
        vid: 'edrpou',
        name: 'EDRPOU',
        rules: 'required|inn_passport'
      },
      attrs: {
        label: 'EDRPOU',
        outlined: true
      }
    },
    {
      model: 'personType',
      component: 'v-select',
      default: items => items[0],
      provider: {
        vid: 'personType',
        name: 'Person type',
        rules: 'required'
      },
      attrs: {
        label: 'Person type',
        outlined: true
      },
      items: () => this.personTypes
    }
  ]

  static ormColsComponents = {
    owner: {
      component: 'e-organization-owner'
    },
    name: {
      component: 'e-organization-name-with-icon'
    }
  }

  static ormDialogs = {
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    read: {
      title: item => ({ name: item.name }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'id',
            label: 'ID'
          },
          {
            model: 'name',
            label: 'Name'
          },
          {
            model: 'ownerFullName',
            label: 'Owner'
          },
          {
            model: 'edrpou',
            label: 'Edrpou'
          },
          {
            model: 'billingPayerCode',
            label: 'Payer code'
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.sendTaxAuthData = function (payload) {
        return this.post(Model.$routes.organization.sendTaxAuthData(), payload, { save: false })
      }
      configActions.addManually = function (payload) {
        return this.post(Model.$routes.organization.addManually(), { ...payload }, { dataKey: null })
      }

      return configActions
    }
  }
}
