export const apiPrefix = '/api/v1'
const routes = {
  // TODO: create dynamic generation of routes.
  login: _ => apiPrefix + '/login',
  acskProviders: 'https://cabinet.tax.gov.ua/ws/api/crypto/public_sign/data/CAs.json',
  auth: {
    refresh_token: _ => apiPrefix + '/token/refresh',
    send_reset_password: _ => apiPrefix + '/user/send-reset-password',
    apply_reset_password: token => apiPrefix + `/user/reset-password/${token}`,
    registration: _ => apiPrefix + '/registration'
  },
  users: {
    list: _ => apiPrefix + '/users',
    authorizeInSfs: _ => ['/users', '/', 'tax-refresh-token'].join('').trim(),
    concrete: id => [apiPrefix, '/users', '/', id].join('').trim(),
    changePassword: _ => [apiPrefix, '/user', '/change-password'].join('').trim(),
    profile: _ => [apiPrefix, '/user', '/profile'].join('').trim(),
    getAuthToken: id => [apiPrefix, '/users', '/', id, '/get-auth'].join('').trim(),
    gatewayToken: _ => [apiPrefix, '/', 'gateway-token'].join('').trim(),
    billing: _ => [apiPrefix, '/user', '/billing'].join('').trim()
  },
  currentuser: {
    concrete: id => [apiPrefix, '/users', '/', id].join('').trim()
  },
  organization: {
    list: _ => apiPrefix + '/organizations',
    concrete: id => [apiPrefix, '/organizations', '/', id].join('').trim(),
    within (model, property = null) {
      return [routes[model.constructor.entity].concrete(model[model.constructor.primaryKey]), property].join('').trim()
    },
    synchronize: id => [apiPrefix, '/organizations', '/', id, '/synchronization'].join('').trim(),
    cashRegisters: id => ['/organizations', '/', id, '/cash-registers'].join('').trim(),
    invoices: id => ['/organizations', '/', id, '/invoices'].join('').trim(),
    sendTaxAuthData: _ => ['/tax', '/update-token'].join(''),
    organizationDataFromTax: _ => [apiPrefix, '/organizations', '/get-tax-info'].join('').trim(),
    addManually: _ => [apiPrefix, '/organizations', '/add-manually'].join('').trim()
  },
  edsKey: {
    check: _ => '/eds-keys/check'
  },
  partners: {
    partnerCheckSupport: (id, partnerName) => [apiPrefix, '/', partnerName, '/organization', '/', id, '/check-support'].join('').trim(),
    partnerRegistration: partnerName => [apiPrefix, '/', partnerName, '/registration'].join('').trim(),
    addOrganizationToPartner: (partnerName, id) => [apiPrefix, '/', partnerName, '/organization/', id, '/add'].join('').trim(),
    partnerRegistrationStatusCheck: (partnerName, id) => [apiPrefix, '/', partnerName, '/registration', '/', id, '/check'].join('').trim(),
    addOrganizationStatusCheck: (partnerName, id) => [apiPrefix, '/', partnerName, '/organization', '/', id, '/check'].join('').trim(),
    partnerGetSuccessRegistration: partnerName => [apiPrefix, '/', partnerName, '/get-success-registration'].join('').trim(),
    partnerRecreateAuthLink: partnerName => [apiPrefix, '/', partnerName, '/create-auth-link'].join('').trim(),
    partnerSubscribe: _ => [apiPrefix, '/', 'subscriptions'].join('').trim()
  },
  billings: {
    list: _ => apiPrefix + '/billings',
    concrete: id => [apiPrefix, '/billings', '/', id].join('').trim(),
    activate: id => [apiPrefix, '/billings', '/', id, '/activate'].join('').trim(),
    within (model, property = null) {
      return [routes[model.constructor.entity].concrete(model[model.constructor.primaryKey]), property].join('').trim()
    }
  },
  periods: {
    list: _ => apiPrefix + '/periods',
    concrete: id => [apiPrefix, '/periods', '/', id].join('').trim(),
    within (model, property = null) {
      return [routes[model.constructor.entity].concrete(model[model.constructor.primaryKey]), property].join('').trim()
    }
  },
  debts: {
    list: _ => apiPrefix + '/debts',
    concrete: id => [apiPrefix, '/debts', '/', id].join('').trim()
  },
  debt_items: {
    list: _ => apiPrefix + '/debt-items',
    concrete: id => [apiPrefix, '/debt-items', '/', id].join('').trim(),
    within (model, property = null) {
      return [routes[model.constructor.entity].concrete(model[model.constructor.primaryKey]), property].join('').trim()
    }
  },
  invoice: {
    list: _ => apiPrefix + '/invoices',
    concrete: id => [apiPrefix, '/invoices', '/', id].join('').trim(),
    getPdf: id => [apiPrefix, '/invoices', '/', id, '/pdf'].join(''),
    getArchive: _ => [apiPrefix, '/invoices', '/archive'].join('').trim(),
    getActFile: id => [apiPrefix, '/acts', '/', id, '/get-file'].join(''),
    sendToEmail: id => [apiPrefix, '/invoices', '/', id, '/send-email'].join(''),
    paymentLink: _ => [apiPrefix, '/ipay', '/link'].join('').trim()
  },
  payments: {
    list: _ => apiPrefix + '/payments',
    concrete: id => [apiPrefix, '/payments', '/', id].join('').trim(),
    changeDescription: id => [apiPrefix, '/payments', '/', id, '/change-description'].join('').trim()
  },
  commissions: {
    list: _ => apiPrefix + '/commissions',
    concrete: id => [apiPrefix, '/commissions', '/', id].join('').trim(),
    markAsSend: id => [apiPrefix, '/commissions', '/', id, '/mark-as-send'].join('').trim(),
    export: id => [apiPrefix, '/commissions', '/', id, '/export'].join('').trim()
  },
  commission_items: {
    list: _ => apiPrefix + '/commission-items'
  },
  distributed_payment: {
    list: _ => apiPrefix + '/distributed-payments',
    concrete: id => [apiPrefix, '/distributed-payments', '/', id].join('').trim()
  },
  client_balances: {
    list: _ => apiPrefix + '/client-balances',
    export: _ => [apiPrefix, '/client-balances', '/export'].join('').trim()
  },
  client_integrations: {
    list: _ => apiPrefix + '/user-integrations',
    concrete: id => [apiPrefix, '/user-integrations/', id].join('').trim()
  },
  client_organization_integrations: {
    list: _ => apiPrefix + '/organization-integrations',
    concrete: id => [apiPrefix, '/organization-integrations/', id].join('').trim()
  }
}

export default routes
