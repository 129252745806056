import { Model } from '@vuex-orm/core'
import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import DistributedPayment from '~/models/billing/DistributedPayment'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import PersistAble from '~/models/mixins/PersistAble'
import Billing from '~/models/billing/Billing'
import Dialog from '~/models/system/Dialog'
import { DateTime } from '~/services/_utils/DateTime'

export default class Payment extends ChainInheritance(Administration, [
  SoftDeletAble,
  TimestampAble,
  PersistAble
]) {
  static entity = 'payments'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'entryDate'
  static defaultSortOrder = true

  static STATUS_NEW = 'new';
  static STATUS_ERROR = 'error';
  static STATUS_PARSED = 'parsed';

  static fields () {
    return super.fields({
      billing_id: this.attr(null),
      distribution_id: this.attr(null),

      id: this.attr(null),
      billing: this.belongsTo(Billing, 'billing_id'),
      distribution: this.belongsTo(DistributedPayment, 'distribution_id'),
      sourcePayerName: this.attr(null),
      sourcePayerCode: this.attr(null),
      sourceName: this.attr(null),
      sourceId: this.attr(null),
      status: this.attr(null),
      errorMessage: this.attr(null),
      description: this.attr(null),
      sum: this.attr(null),
      entryDate: this.attr(null)
    })
  }

  get userName () {
    return this._.get(this, 'user.profile.name', '')
  }

  get sumString () {
    return this.sum ? this.sum / 100 : null
  }

  get entryDateString () {
    return DateTime.format(this.entryDate)
  }

  get distributionString () {
    const output = []
    const dnTrade = this._.get(this.distribution, 'dnTradeAmount')
    const bookKeeper = this._.get(this.distribution, 'bookKeeperAmount')
    const hugeProfit = this._.get(this.distribution, 'hugeProfitAmount')

    if (dnTrade) {
      output.push(`DNT: ${(dnTrade / 100).toFixed(0)}`)
    }
    if (bookKeeper) {
      output.push(`BK: ${(bookKeeper / 100).toFixed(0)}`)
    }
    if (hugeProfit) {
      output.push(`HP: ${(hugeProfit / 100).toFixed(0)}`)
    }

    return output.join(' / ')
  }

  get sourcePayer () {
    const output = []

    if (this.sourcePayerCode) {
      output.push(this.sourcePayerCode)
    }
    if (this.sourcePayerName) {
      output.push(this.sourcePayerName)
    }

    return output.join(' / ')
  }

  static ormFilters = [
    {
      model: 'status',
      component: 'v-select',
      provider: {
        name: 'Status',
        vid: 'status',
        rules: ''
      },
      attrs: {
        label: 'Status',
        outlined: true,
        clearable: true,
        'return-object': false
      },
      items: () => [Payment.STATUS_ERROR, Payment.STATUS_NEW, Payment.STATUS_PARSED]
    },
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 6
              },
              fields: [
                'status'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 6
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormTrans = {
    single: 'Billing',
    multy: 'Billing'
  }

  static ormHeaders = [
    { text: 'Source name', value: 'sourceName', sortable: false, filterable: false },
    { text: 'Source id', value: 'sourceId', sortable: false, filterable: false },
    { text: 'Status', value: 'status', sortable: false, filterable: false },
    { text: 'Description', value: 'description', sortable: false, filterable: false },
    { text: 'Payer', value: 'sourcePayer', sortable: false, filterable: false },
    { text: 'Sum', value: 'sumString', sortable: false, filterable: false },
    { text: 'Distribution', value: 'distributionString', sortable: false, filterable: false },
    { text: 'Payment date', value: 'entryDateString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['status'],
      'sort-desc': [true]
    })
  }

  static ormActions = [
    {
      name: 'apply_for_user',
      text: 'Apply to user',
      icon: {
        type: 'e-svg-icon',
        text: 'money'
      },
      visible: item => item.status === Payment.STATUS_ERROR,
      call: (item, someField, ctx) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          title: 'Change payment',
          component: 'm-form-block',
          width: '600px',
          componentProps: {
            buttonText: 'Save',
            fields: [
              {
                model: 'description',
                component: 'v-textarea',
                provider: {
                  vid: 'description',
                  name: 'Description',
                  rules: 'required'
                },
                attrs: {
                  label: 'Description',
                  outlined: true
                },
                default: item.description
              }
            ],
            successMessage: 'Payment processed',
            onSubmit: async ({ description }) => {
              await Payment.api().applyForUser(item.id, { description })
              await dialog.close()
            }
          }
        })
      }
    },
    {
      name: 'distributed_payments',
      text: 'Distributed payments',
      icon: {
        type: 'e-svg-icon',
        text: 'payment'
      },
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        await dialog.open({
          width: '600px',
          title: 'Distributed payments',
          component: 'm-form-block',
          componentProps: {
            buttonText: 'Save',
            fields: [
              {
                model: 'dnTradeAmount',
                component: 'v-text-field',
                provider: {
                  vid: 'dnTradeAmount',
                  name: 'DnTrade Amount',
                  rules: 'required|numeric'
                },
                attrs: {
                  type: 'number',
                  label: 'DnTrade Amount',
                  outlined: true
                },
                default: this._.get(item, 'distribution.dnTradeAmount') || '0',
                cast: val => val && +val
              },
              {
                model: 'bookKeeperAmount',
                component: 'v-text-field',
                provider: {
                  vid: 'bookKeeperAmount',
                  name: 'BookKeeper Amount',
                  rules: 'required|numeric'
                },
                attrs: {
                  type: 'number',
                  label: 'BookKeeper Amount',
                  outlined: true
                },
                default: this._.get(item, 'distribution.bookKeeperAmount') || '0',
                cast: val => val && +val
              },
              {
                model: 'hugeProfitAmount',
                component: 'v-text-field',
                provider: {
                  vid: 'hugeProfitAmount',
                  name: 'HugeProfit Amount',
                  rules: 'required|numeric'
                },
                attrs: {
                  type: 'number',
                  label: 'HugeProfit Amount',
                  outlined: true
                },
                default: this._.get(item, 'distribution.hugeProfitAmount') || '0',
                cast: val => val && +val
              },
              {
                model: 'totalAmount',
                component: 'v-text-field',
                provider: {
                  vid: 'totalAmount',
                  name: 'Total Amount'
                },
                attrs: {
                  type: 'number',
                  label: 'Total Amount',
                  outlined: true,
                  disabled: true
                },
                default: this._.get(item, 'distribution.totalAmount') || '0'
              }
            ],
            successMessage: 'Distribution updated',
            onSubmit: async (form) => {
              const url = Model.$routes[DistributedPayment.entity].concrete(item.distribution.id)

              await DistributedPayment.api().put(url, form, { dataKey: null })
              await dialog.close()
            }
          }
        })
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.applyForUser = function (id, payload) {
        return this.put(Model.$routes.payments.changeDescription(id), payload, { dataKey: null }
        )
      }

      return configActions
    }
  }
}
