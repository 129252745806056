import ChainInheritance from '~/models/mixins/ChainInheritance'
import Administration from '~/models/abstracts/Administration'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import PersistAble from '~/models/mixins/PersistAble'

export default class DistributedPayment extends ChainInheritance(Administration, [
  SoftDeletAble,
  PersistAble
]) {
  static entity = 'distributed_payment'
  static paginated = true
  static defaultSortOrder = false
  static ormLoadWithRelations = true

  static fields () {
    return super.fields({
      id: this.attr(null),
      status: this.attr(null),
      dnTradeAmount: this.attr(null),
      bookKeeperAmount: this.attr(null),
      hugeProfitAmount: this.attr(null),
      totalAmount: this.attr(null)
    })
  }

  static ormTrans = {
    single: 'Distributed payment',
    multy: 'Distributed payments'
  }
}
