<template lang="pug">
  div(class="block-connect-checkbox")
      h2(class="block-connect-checkbox__title") {{ $t('Connect PRRO') }}
      p(class="block-connect-checkbox__text") {{ $t('If you are already a Checkbox user, select the module in which you want to issue fiscal receipts') }}
      div(class="block-connect-checkbox__partner")
          div(class="d-flex align-center")
            div(class="block-connect-checkbox__partner-logo")
                img(:src="require('~/assets/sprite/svg/building-24.svg')")
            h3 {{ $t('navkolo.trading') }}
          template(v-if="isConnectedDnTrade")
            a(
              class="main-button outlined block-connect-checkbox__link"
              target="_blank"
              :href="`redirect/${this.dnTradeKey}`"
            ) {{ $t('Go') }}
          template(v-else)
            v-btn(
              class="main-button outlined block-connect-checkbox__btn"
              @click="connectPartner(dnTradeKey)"
              :loading="connectLoading"
            ) {{ $t('Connect product') }}
      div(class="block-connect-checkbox__partner")
          div(class="d-flex align-center")
            div(class="block-connect-checkbox__partner-logo")
                img(:src="require('~/assets/sprite/svg/settings-4.svg')")
            h3 {{ $t('navkolo.CRM') }}
          template(v-if="isConnectedHugeProfit")
            a(
              class="main-button outlined block-connect-checkbox__link"
              target="_blank"
              :href="`redirect/${this.hugeProfitKey}`"
            ) {{ $t('Go') }}
          template(v-else)
            v-btn(
              class="main-button outlined block-connect-checkbox__btn"
              @click="connectPartner(hugeProfitKey)"
              :loading="connectLoading"
            ) {{ $t('Connect product') }}
      h2(class="block-connect-checkbox__title") {{ $t('Register PRRO') }}
      p(class="block-connect-checkbox__text") {{ $t('If you are not a Checkbox user, please register') }}
      a(
        class="main-button block-connect-checkbox__link block-connect-checkbox__link--xl w-50 mb-4"
        target="_blank"
        href="https://my.checkbox.ua/auth/registration"
      ) {{ $t('Registration') }}
      p(class="block-connect-checkbox__text") {{ $t('After registering in Checkbox, return to Navkolo and connect the module in which you want to issue fiscal receipts') }}
</template>

<script>
import Partners from '~/modules/partners/models/Partners'

export default {
  name: 'MBlockConnectCheckbox',
  props: {
    isConnectedDnTrade: {
      type: Boolean,
      required: true,
      default: false
    },
    isConnectedHugeProfit: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      connectLoading: false
    }
  },
  computed: {
    dnTradeKey () {
      return this._.camelCase(Partners.keys.dnTrade)
    },
    hugeProfitKey () {
      return this._.camelCase(Partners.keys.hugeProfit)
    }
  },
  methods: {
    connectPartner (key) {
      this.$emit('connectPartner', key)
      this.$attrs.closeModal(true)
    }
  }
}
</script>

<style scoped lang="scss">
.block-connect-checkbox {
  padding: 40px 28px;

  &__title {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(7, 6, 0, 0.7);
  }
  &__partner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;
      background: linear-gradient(0deg, #000000, #000000), #161B25;
      border-radius: 100%;
      margin-right: 12px;

      img {
        width: 24px;
        height: auto;
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  &__btn {
    height: 40px !important;
    min-height: 40px !important;
    border-radius: 12px !important;
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px !important;
    padding: 0 40px;
    min-height: 40px !important;
    height: 40px !important;

    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;

    &--xl {
      min-height: 52px !important;
      height: 52px !important;
    }
  }
}
</style>
