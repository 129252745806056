import { get } from 'lodash'
import AuthToken from '~/modules/auth/Models/AuthToken'
import Organization from '~/models/directories/Organization'
import CurrentUser from '~/modules/auth/Models/CurrentUser'

export default {
  login (login, password) {
    return AuthToken.api().getToken(login.toLowerCase(), password)
  },
  loginById (userId) {
    return AuthToken.api().getTokenForUser(userId)
  },

  /**
   * This used for login by other user from super admin user
   */
  async loginAsOtherUser (userId) {
    if (!this.getAuthToken()) { return }
    const superAdminToken = this.getAuthToken()
    await this.loginById(userId)
    superAdminToken.userType = AuthToken.userTypes.SUPER_ADMIN
    await superAdminToken.$save()
    await Promise.all([
      CurrentUser.deleteAll(),
      Organization.deleteAll()
    ])
    // await superAdminToken.
  },

  /**
   * This used for super admin user
   */
  async applyAdminToken () {
    if (this.getSuperAdminAuthToken()) {
      const adminToken = this.getSuperAdminAuthToken()
      await this.logout()
      adminToken.userType = AuthToken.userTypes.USER
      await adminToken.$save()
    }
  },

  logout () {
    return Promise.all([
      AuthToken.deleteAll(),
      CurrentUser.deleteAll(),
      Organization.deleteAll()
    ])
  },

  refreshToken () {
    return AuthToken.api().refresh(this.getRefreshToken())
  },

  getAuthToken () {
    return AuthToken.query().where('userType', AuthToken.userTypes.USER).first()
  },

  getSuperAdminAuthToken () {
    return AuthToken.query().where('userType', AuthToken.userTypes.SUPER_ADMIN).first()
  },

  getToken () {
    const authToken = this.getAuthToken()
    return get(authToken, 'token', null)
  },

  getBearer () {
    const token = this.getToken()
    return token ? 'Bearer ' + token : null
  },

  getRefreshToken () {
    const authToken = this.getAuthToken()
    return get(authToken, 'refresh_token', null)
  },

  isAuthenticated () {
    return !!this.getAuthToken()
  }
}
