import { Model } from '@vuex-orm/core'
import { formats } from '~/const/global'
import { DateTime } from '~/services/_utils/DateTime'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import ClientOrganizationIntegration from '~/models/integrations/ClientOrganizationIntegration'
import Administration from '~/models/abstracts/Administration'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import PersistAble from '~/models/mixins/PersistAble'
import Partners from '~/modules/partners/models/Partners'
import Dialog from '~/models/system/Dialog'

export default class ClientIntegration extends ChainInheritance(Administration, [
  SoftDeletAble,
  PersistAble
]) {
  static entity = 'client_integrations'
  static paginated = true
  static defaultSortOrder = true

  static fields () {
    return super.fields({
      id: this.attr(null),
      user: this.attr(null),
      status: this.attr(null),
      provider: this.attr(null),
      errorMessage: this.attr(null),
      organizationIntegrationsCount: this.attr(null),
      startAt: this.attr(null)
    })
  }

  static ormTrans = {
    single: 'Client integration',
    multy: 'Client integrations'
  }

  get userLogin () {
    return this._.get(this.user, 'login')
  }

  get partnerName () {
    switch (this.provider) {
      case Partners.keys.dnTrade:
        return 'DNTrade'
      case Partners.keys.hugeProfit:
        return 'HugeProfit'
      case Partners.keys.bookKeeper:
        return 'BookKeeper'
      case Partners.keys.masterBuh:
        return 'MasterBuh'
      default:
        return 'n/a'
    }
  }

  get createdDateString () {
    return DateTime.format(this.startAt, formats.dateTime)
  }

  get errorMessageString () {
    return this.errorMessage || '-'
  }

  static ormHeaders = [
    { text: 'User', value: 'userLogin', sortable: false, filterable: false },
    { text: 'Status', value: 'status', sortable: false, filterable: false },
    { text: 'Provider', value: 'partnerName', sortable: false, filterable: false },
    { text: 'Created ', value: 'createdDateString', sortable: false, filterable: false },
    { text: 'Error', value: 'errorMessageString', sortable: false, filterable: false },
    { text: 'Organization integrations count', value: 'organizationIntegrationsCount', width: '100', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormFilters = [
    {
      model: 'user.login',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'E-mail (login)',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    },
    {
      model: 'provider',
      component: 'v-select',
      provider: {
        vid: 'provider',
        name: 'provider'
      },
      attrs: {
        label: 'Partner',
        outlined: true,
        clearable: true,
        'return-object': false
      },
      items: () => Object.entries(Partners.keys).map(([item, key]) => ({ text: item, value: key }))
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 6
              },
              fields: [
                'user.login'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 6
              },
              fields: [
                'provider'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = [
    {
      name: 'organizationIntegrations',
      text: 'Підключені організації',
      icon: {
        type: 'e-svg-icon',
        text: 'shopping-cart'
      },
      visible: item => item.organizationIntegrationsCount,
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          width: 'full',
          title: `Підключені організації користувача ${item.user.login} до партнера ${item.provider}`,
          component: 'm-orm-table',
          componentProps: {
            model: ClientOrganizationIntegration,
            customRequest: ({ model }) => {
              return model.api().filter({ 'userIntegration.id': item.id }).all({ persistBy: 'create' })
            }
          }
        })
      }
    },
    {
      name: 'delete'
    }
  ]

  static ormDialogs = {
    delete: 'm-orm-delete-dialog'
  }

  static ormDialogsConfig = {
    delete: {
      title: item => ({ type: '', name: `інтеграцію ${item.provider} по всіх організаціях користувача ${item.user.login}?` }),
      notification: item => ({ type: '', name: `інтеграцію ${item.provider} по всіх організаціях користувача ${item.user.login}` })
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      return configActions
    }
  }
}
