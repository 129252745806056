export function formatPriceFromApi (value) {
  if (typeof value !== 'number') {
    return
  }

  return (value / 100).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).replace(/,/g, ' ')
}
