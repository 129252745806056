import { formats } from '~/const/global'
import { DateTime } from '~/services/_utils/DateTime'
import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import PersistAble from '~/models/mixins/PersistAble'

export default class ClientOrganizationIntegration extends ChainInheritance(Administration, [
  SoftDeletAble,
  PersistAble
]) {
  static entity = 'client_organization_integrations'
  static paginated = true
  static defaultSortOrder = true
  static ormLoadWithRelations = true

  static fields () {
    return super.fields({
      id: this.attr(null),
      status: this.attr(null),
      organization: this.attr(null),
      errorMessage: this.attr(null),
      startAt: this.attr(null)
    })
  }

  static ormTrans = {
    single: 'Client integration',
    multy: 'Client integrations'
  }

  get organizationString () {
    return this._.get(this.organization, 'name')
  }

  get organizationEdrpouString () {
    return this._.get(this.organization, 'edrpou')
  }

  get createdDateString () {
    return DateTime.format(this.startAt, formats.dateTime)
  }

  get errorMessageString () {
    return this.errorMessage || '-'
  }

  static ormHeaders = [
    { text: 'Organization ', value: 'organizationString', sortable: false, filterable: false },
    { text: 'Edrpou', value: 'organizationEdrpouString', sortable: true, filterable: true },
    { text: 'Created ', value: 'createdDateString', sortable: false, filterable: false },
    { text: 'Error', value: 'errorMessageString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormActions = [
    {
      name: 'delete'
    }
  ]

  static ormDialogs = {
    delete: 'm-orm-delete-dialog'
  }

  static ormDialogsConfig = {
    delete: {
      title: item => ({ type: '', name: `інтеграцію з організацією ${item.organization.name}` }),
      notification: item => ({ type: '', name: `інтеграцію з організацією ${item.organization.name}` })
    }
  }
}
