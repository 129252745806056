import authUser from '~/modules/auth/authUser'

export default function ({ store, route, redirect }) {
  if (!authUser.get()?.isSuperAdmin) {
    if (route.name !== 'dashboard') {
      redirect('/dashboard')
    }
  } else if (route.name === 'dashboard') {
    redirect('/admin/organizations')
  }
}
