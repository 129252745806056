// import { HttpLogError } from '~/services/Error/HttpLogError'

const popupErrors = {
  'Processing: CheckBoxПідпис неактивний, запустіть його, будь ласка': 'sign inactive'
}

const descriptionErrors = {
  'Помилка при відкритті зміни: ForceOffline: Force offline mode enabled': 'ДПС недоступний! Каса працює в офлайні! Для першого запуску каси необхідно отримати офлайн коди. Прохання повторити операцію через 20-30 хвилин після відновлення роботи ДПС.'
}

const infoErrors = ['в касі недостатньо коштів']

function checkInfoError (msg) {
  return Boolean(infoErrors.filter(err => msg.includes(err)).length)
}

export default ({ req, res, app }, inject) => {
  inject('handlers', {
    forbidden (res) {
      app.$notification.error(res.data.detail || res.data.message)
    },
    error (e, form, silent = false) {
      // send logs
      // const error = new HttpLogError(e)

      if (silent) {
        return null
      }
      // Show errors in dev mode.
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(e)
        if ([500, 503].includes(e.response && e.response.status)) {
          form.$notification.error(['API error:', e.response.data.title].join(' ').trim())
        }
      }

      if (e.response && (e.response.status === 404 || (e.response.status === 403 && !form.$Organization))) {
        return
      }

      const message = form._.get(e, 'response.data.detail', null) || form._.get(e, 'response.data.message', null)

      if (e.response && e.response.status === 400 && e.response.data && Array.isArray(e.response.data.violations)) {
        const errors = {}

        e.response.data.violations.forEach((violation) => {
          if (!errors[violation.propertyPath]) {
            errors[violation.propertyPath.split('.').pop()] = [violation.message]
          }
        })

        if (form.setErrors) {
          form.setErrors(errors)
        } else {
          showMessage(message)
        }
        return null
      }

      if (e.response && e.response.status === 401) {
        if (form.fields) {
          const fields = Object.keys(form.fields || {})
          const errors = {}

          fields.forEach((field) => {
            errors[field] = [e.response.data.message]
          })

          form.setErrors ? form.setErrors(errors) : showMessage(errors)
        } else {
          showMessage(message)
        }
        return null
      }

      if (e.response && e.response.status === 404) {
        if (e.response.data && (e.response.data.detail || e.response.data.message)) {
          showMessage(e.response.data.detail || e.response.data.message)
        } else {
          showMessage('The requested resource does not exist')
        }
        return null
      }

      if (e.response && e.response.status === 500) {
        showMessage('API error please contact the manager')
        return null
      }

      if (e.response && e.response.data && message) {
        showMessage(message)
      } else if (e.message || typeof e === 'string') {
        showMessage(e.message || e)
      } else {
        showMessage('Error occurred. Try again later or contact with administrator')
      }

      function showMessage (message) {
        const msg = descriptionErrors[message] || message
        if (msg in popupErrors) {
          app.store.dispatch('dialogs/openWithOptions', {
            type: 'mDialogInfo',
            options: {
              text: form.$t(popupErrors[msg]),
              button: {
                show: true,
                text: form.$t('Okay')
              }
            }
          })
        } else if (checkInfoError(msg)) {
          form.$notification.info(form.$t(msg))
        } else {
          form.$notification.error(form.$t(msg))
        }
      }
    }
  })
}
