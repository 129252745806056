<template lang="pug">
  v-form(class="change-name py-8")
    h2(class="mb-6") {{ $t('Change phone number') }}
    ValidationObserver(ref="changePhoneForm")
      t-orm-fields(:phone.sync="phone" :items="fields")
    v-btn(
      class="main-button w-100"
      :loading="loading"
      @click="submit"
    ) {{ $t('Change') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import User from '~/models/administration/User'

export default {
  name: 'MFormChangePhone',
  components: {
    TOrmFields
  },
  props: {
    currentPhone: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    phone: '',
    loading: false
  }),
  computed: {
    fields () {
      return [
        {
          model: 'phone',
          component: 'e-input-mask',
          provider: {
            name: 'Phone',
            vid: 'phone',
            rules: 'required|phone',
            mode: 'lazy'
          },
          attrs: {
            label: 'Enter your phone',
            mask: 'mobile',
            outlined: true,
            required: true
          },
          classes: ['app-filled-input']
        }
      ]
    }
  },
  created () {
    this.phone = this.currentPhone
  },
  methods: {
    async submit () {
      const valid = await this.$refs.changePhoneForm.validate()

      if (valid) {
        try {
          this.loading = true

          await User.api().updateUserProfile({ phone: this.phone })

          this.$attrs.closeModal(true, true)
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.change-name {
  color: $dark;
}
</style>
