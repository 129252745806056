import { Model } from '@vuex-orm/core'
import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import PersistAble from '~/models/mixins/PersistAble'
import Debt from '~/models/billing/Debt'
import AppNotifications from '~/services/Notifications/AppNotifications'
import { DateTime } from '~/services/_utils/DateTime'

export default class Invoice extends ChainInheritance(Administration, [
  SoftDeletAble,
  PersistAble
]) {
  static entity = 'invoice'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortOrder = true
  static persistOptions = {
    insertOrUpdate: ['debts']
  }

  static fields () {
    return super.fields({
      debt_id: this.attr(null),

      id: this.attr(null),
      debt: this.belongsTo(Debt, 'debt_id'),
      debtItems: this.attr([]),
      type: this.attr(null),
      sendTo: this.attr(null),
      sendAt: this.attr(null),
      contrAgentName: this.attr(null),
      receiverEmail: this.attr(null),
      date: this.attr(null),
      number: this.attr(null),
      sendError: this.attr(null),
      act: this.attr(null)
    })
  }

  get sum () {
    let sum = 0
    this.debtItems.forEach((item) => {
      sum += (item.count * item.price) - (item.count * item.price * (item.discount / 100))
    })
    return (sum - sum * (this.debt.discount / 100)) / 100
  }

  get dateString () {
    return DateTime.format(this.date)
  }

  get sendAtString () {
    return DateTime.format(this.sendAt)
  }

  static ormTrans = {
    single: 'Invoice',
    multy: 'Invoices'
  }

  static ormHeaders = [
    { text: 'Date', value: 'dateString', sortable: false, filterable: false },
    { text: 'Number', value: 'number', sortable: false, filterable: false },
    { text: 'Sum', value: 'sum', sortable: false, filterable: false },
    { text: 'Contr agent name', value: 'contrAgentName', sortable: false, filterable: false },
    { text: 'Receiver email', value: 'receiverEmail', sortable: false, filterable: false },
    { text: 'Send at', value: 'sendAtString', sortable: false, filterable: false },
    { text: 'Помилка', value: 'sendError', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormActions = [
    {
      name: 'downloadFile',
      text: 'Download file',
      call: async (item) => {
        const res = await Invoice.api().getPdf(item.id)
        const blob = new Blob([this._.get(res, 'response.data', '')], { type: 'application/pdf' })
        window.open(URL.createObjectURL(blob), '_blank')
      }
    },
    {
      name: 'downloadActFile',
      text: 'Download Act file',
      visible: item => item.act?.hasFile,
      call: async (item) => {
        const res = await Invoice.api().getActFile(item.act.id)
        const blob = new Blob([this._.get(res, 'response.data', '')], { type: 'application/pdf' })
        window.open(URL.createObjectURL(blob), '_blank')
      }
    },
    {
      name: 'sendToEmail',
      text: 'Sent to email',
      call: async (item) => {
        await Invoice.api().sendToEmail(item.id)
        AppNotifications.success('Рахунок відправлено клієнту')
      }
    },
    {
      name: 'delete'
    }
  ]

  static ormDialogsConfig = {
    delete: {
      title: item => ({ type: this.ormTrans.single, name: item.fullName }),
      notification: item => ({ type: this.ormTrans.single, name: item.fullName })
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.getPdf = function (id) {
        return this.get(
          Model.$routes.invoice.getPdf(id), { save: false, responseType: 'blob' }
        )
      }
      configActions.getArchive = function (payload) {
        return this.post(
          Model.$routes.invoice.getArchive(), payload, { save: false, responseType: 'blob' }
        )
      }
      configActions.getActFile = function (id) {
        return this.get(
          Model.$routes.invoice.getActFile(id), { save: false, responseType: 'blob' }
        )
      }
      configActions.sendToEmail = function (id) {
        return this.put(
          Model.$routes.invoice.sendToEmail(id), {}, { save: true, dataKey: null }
        )
      }
      configActions.paymentLink = function (amount) {
        return this.post(
          Model.$routes.invoice.paymentLink(), { amount }, { save: false }
        )
      }

      return configActions
    }
  }
}
