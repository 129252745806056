<template lang="pug">
  m-info-block(
    :type="alertType"
    class="mt-5"
  )
    template(#title) {{ billingText.title }}
    template(#text) {{ billingText.text }}
      |
      |
      template(v-if="isRenewalDueStatus")
        nuxt-link(to="/payments") {{ $t('make payment') }},
        |
        | {{ $t('until') }} {{ formattedDateToPay }}.
      template(v-else-if="isDisabledStatus")
        nuxt-link(to="/payments") {{ $t('make payment') }}.
</template>

<script>
import Billing from '~/models/billing/Billing'
import MInfoBlock from '~/components/modules/blocks/m-info-block'
import dateTime from '~/mixins/datetime/dateTime'

export default {
  components: {
    MInfoBlock
  },
  mixins: [dateTime],
  props: {
    dateToPay: {
      type: String,
      default: ''
    },
    billingStatus: {
      type: String,
      default: ''
    }
  },
  computed: {
    alertType () {
      if (this.isRenewalDueStatus) {
        return 'warning'
      } else if (this.isDisabledStatus) {
        return 'error'
      } else {
        return null
      }
    },
    billingText () {
      if (this.isRenewalDueStatus) {
        return {
          title: this.$t('Invoices have been sent to your e-mail for the next period.'),
          text: this.$t('To continue using the service, please,')
        }
      } else if (this.isDisabledStatus) {
        return {
          title: this.$t('Your account has been disabled for non-payment.'),
          text: this.$t('Your account has been blocked. Please,')
        }
      } else {
        return null
      }
    },
    formattedDateToPay () {
      return this.formatDate(this.dateToPay)
    },
    isRenewalDueStatus () {
      return [Billing.STATUS_INVOICED, Billing.STATUS_IN_DEBT].includes(this.billingStatus)
    },
    isDisabledStatus () {
      return this.billingStatus === Billing.STATUS_DISABLED
    }
  }
}
</script>

<style scoped>

</style>
