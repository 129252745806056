import { Model } from '@vuex-orm/core'
import { formatPriceFromApi } from '~/services/_utils/Currency'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import Administration from '~/models/abstracts/Administration'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import PersistAble from '~/models/mixins/PersistAble'
import Invoice from '~/models/billing/Invoice'

export default class ClientBalance extends ChainInheritance(Administration, [
  SoftDeletAble,
  PersistAble
]) {
  static entity = 'client_balances'
  static paginated = true
  static defaultSortOrder = true

  static fields () {
    return super.fields({
      id: this.attr(null),
      edrpou: this.attr(null),
      actsAmount: this.attr(null),
      startBalance: this.attr(null),
      paymentsAmount: this.attr(null),
      organizationName: this.attr({}),
      endBalance: this.attr(null)
    })
  }

  static ormTrans = {
    single: 'Client balance',
    multy: 'Client balances'
  }

  get balanceBeforeString () {
    return formatPriceFromApi(this.startBalance || 0)
  }

  get incomingPaymentsString () {
    return formatPriceFromApi(this.paymentsAmount || 0)
  }

  get outgoingPaymentsString () {
    return formatPriceFromApi(this.actsAmount ? Math.abs(this.actsAmount) : 0)
  }

  get balanceAfterString () {
    return formatPriceFromApi(this.endBalance || 0)
  }

  static ormHeaders = [
    { text: 'EDRPOU', value: 'edrpou', sortable: false, filterable: false },
    { text: 'Назва організації', value: 'organizationName', sortable: false, filterable: false },
    { text: 'Аванс на початок періоду, грн', value: 'balanceBeforeString', sortable: false, filterable: false },
    { text: 'Надійшло оплат, грн', value: 'incomingPaymentsString', sortable: false, filterable: false },
    { text: 'Надано послуг, грн', value: 'outgoingPaymentsString', sortable: false, filterable: false },
    { text: 'Аванс на кінець періоду, грн', value: 'balanceAfterString', sortable: false, filterable: false }
  ]

  static ormFilters = [
    {
      type: 'dateRange',
      model: 'date',
      // component: 'e-input-datetime',
      component: 'e-input-datetime-range',
      attrs: {
        type: 'date',
        label: 'Date',
        range: false,
        clearable: true,
        closeOnClick: true,
        emmitOnlyOnSelectClicked: true,
        'hide-details': true,
        outlined: true
      }
    },
    {
      model: 'edrpou',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'EDRPOU',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 4
              },
              fields: [
                'date'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 4
              },
              fields: [
                'edrpou'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = [
    {
      name: 'downloadActFile',
      text: 'Download Act file',
      visible: item => false,
      call: async (item) => {
        const res = await Invoice.api().getActFile(item.actId)
        const blob = new Blob([this._.get(res, 'response.data', '')], { type: 'application/pdf' })
        window.open(URL.createObjectURL(blob), '_blank')
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.export = function (dates) {
        const query = new URLSearchParams({ 'date[after]': dates[0], 'date[before]': dates[1] })
        return this.get(Model.$routes.client_balances.export() + '?' + query.toString(), { responseType: 'blob', save: false })
      }

      return configActions
    }
  }
}
