<template lang="pug">
  v-card(class="org-form px-5 py-7")
    v-row(v-if="!isManual && !showConnectProductForm" justify="center" align="center" no-gutters)
      v-col(cols="12")
        h2(class="org-form__title" :class="{'text-center': orgExists || showOrgData}") {{ title }}

        div(v-if="showOrgData")
          v-row(
            v-if="showOrgData[key]"
            v-for="(value, key, index) in showOrgData"
            :key="index"
          )
            v-col(cols="6")
              span {{ $t(key) }}:
            v-col(cols="6")
              span {{ $t(value) }}

        div(v-else)
          p(class="org-form__text") {{ $t('not to enter information manually, use EDS key instead') }}
        p(id="ecp_setup_validation_error" class="red--text" v-if="orgExists") {{ $t('Organization exists') }}
        div(v-if="showManuallyData")
          p(class="org-form__text font-weight-bold my-4") {{ $t('But, unfortunately, some data are not available in the traffic police, so we suggest entering them manually') }}:
          ValidationObserver(
            ref="manuallyData"
            slim
          )
            ValidationProvider(
              v-if="!_.get(orgData, 'ownedOrganization.name')"
              :name="$t('Organization name')"
              vid="name"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="manuallyData.name"
                :label="$t('Organization name')"
                :error-messages="errors"
                outlined
              )
            ValidationProvider(
              v-if="showTaxationTypeChoose"
              :name="$t('The type of taxation')"
              vid="tax"
              mode="lazy"
              v-slot="{ errors }"
            )
              v-checkbox(
                :label="$t('I am a vat payer')"
                v-model="manuallyData.isVAT"
                class="mt-0"
              )
      v-col(cols="12" class="mt-8")
        div(class="org-form__btns-wrap")
          e-button-main(
            v-if="!showOrgData"
            :enableIcon="false"
            appearance="empty"
            :loading="isManual"
            @click="handleManual"
            class="main-button plain flex-grow-1"
          ) {{ $t('Fill manually') }}
          e-button-main(
            :enableIcon="false"
            @click="btnClick"
            class="main-button flex-grow-1"
            :loading="orgDataLoading"
          ) {{ btnText }}
    v-row(v-if="isManual" justify="center" align="center" no-gutters)
      v-col(cols="12")
        m-block-add-organization-manually(
            @back="isManual = false"
            @closeModal="$attrs.closeModal"
        )
    v-row(v-else-if="showConnectProductForm")
      v-col(cols="12")
        m-form-connect-organization(
          :is-create-organization="isLater"
          :partner-name="partnerName"
          :is-form-opened-in-another-component="true"
          :showFullForm="partnerName === 'bookKeeper' || partnerName === 'masterBuh'"
          @close="$attrs.closeModal"
        )
</template>

<script>
import EButtonMain from '~/components/elements/buttons/e-button-main'
import ELink from '~/components/elements/links/e-link'
import Organization from '~/models/directories/Organization'
import MBlockAddOrganizationManually from '~/components/modules/blocks/concrete/m-block-add-organization-manually'
import addOrgAutomaticEds from '~/mixins/addOrgAutomaticEds'
import contentDialog from '~/mixins/dialogs/contentDialog'
import MFormConnectOrganization from '~/components/modules/forms/concrete/m-form-connect-organization'

export default {
  components: {
    MFormConnectOrganization,
    ELink,
    EButtonMain,
    MBlockAddOrganizationManually
  },
  mixins: [addOrgAutomaticEds, contentDialog],
  props: {
    isLater: {
      type: Boolean,
      default: false
    },
    partnerName: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    showManuallyData: false,
    showConnectProductForm: false,
    manuallyData: {
      isVAT: null,
      name: null
    },
    isManual: false
  }),
  computed: {
    showTaxationTypeChoose () {
      return !this._.get(this.orgData, 'ownedOrganization.isVAT') && !this._.get(this.orgData, 'ownedOrganization.isSingleTax')
    },
    title () {
      if (this.showOrgData) {
        return `${this.$t('Thank you')}, ${this.$t('we received the following data from the SFS')}:`
      } else {
        return this.$t('Add an organization')
      }
    },
    btnText () {
      if (this.showOrgData) {
        return this.$t('Add organization')
      } else {
        return this.$t('Automatically (EDS)')
      }
    }
  },
  methods: {
    async btnClick () {
      if (this.showManuallyData) {
        const valid = await this.$refs.manuallyData.validate()
        if (!valid) {
          return
        } else {
          const { name: manuallyName, isVAT } = this.manuallyData
          const name = manuallyName || this._.get(this.orgData, 'ownedOrganization.name', '')
          const shortName = this._.get(this.orgData, 'ownedOrganization.shortName', '') || name
          this.$set(this.orgData, 'ownedOrganization', {
            ...this._.get(this.orgData, 'ownedOrganization', {}),
            name,
            shortName,
            isVAT: !!isVAT,
            isSingleTax: !isVAT
          })
        }
      }

      if (this.showOrgData) {
        await this.addOrganization()
      } else {
        await this.createOrgAutomatically()
      }
    },
    async addOrganization () {
      try {
        this.orgDataLoading = true
        const payload = this._.get(this.orgData, 'ownedOrganization', null)
        const token = this._.get(this.orgData, 'token', null)

        await Organization.api().create(Object.assign({}, payload, { token }))
        // GTM adding organization
        window.dataLayer && window.dataLayer.push({ event: 'added_organization_сes', step: 'onboarding_product' })
        this.$notification.success(this.$t('Organization successfully added'))
        this.$nextTick(() => {
          this.$attrs.closeModal(true)
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.orgDataLoading = false
      }
    },
    handleManual () {
      if (this.isLater) {
        this.showConnectProductForm = true
      } else {
        this.isManual = !this.isManual
      }
    }
  }
}
</script>

<style scoped lang="scss">
.org-form {
  width: 100%;
  box-shadow: none;

  &__btns-wrap {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    font-size: 16px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-direction: column-reverse;
      gap: 20px;
    }
  }

  &__title {
    font-family: $card-title-font-family;
    font-size: $card-title-font-size;
    line-height: $card-title-line-height;
    word-break: break-word;
    font-weight: $card-title-font-weight;
    margin-bottom: 24px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      margin-bottom: 12px;

      font-size: $card-title-font-size-mobile;
      line-height: $card-title-line-height-mobile;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(7, 6, 0, 0.7);
  }

  ::v-deep {
    .v-text-field {
      &--outlined {
        .v-label {
          top: 13px;

          &--active {
            background: #fff;
            padding: 0 5px;
            transform: translateY(-21px) scale(0.75);
          }
        }
      }
    }

    .v-input:not(.v-input--radio-group, .v-input--checkbox) {
      .v-input__slot {
        background: $input-bg-gray;
        border-radius: $input-border-radius;
        height: 48px;
        padding-left: 16px !important;
        color: #161B25;
        font-size: 14px;
        position: relative;
        z-index: 2;

        fieldset {
          border: none;
        }

        .v-input__append-inner {
          margin-top: 12px;
        }
      }

      .v-input__control {
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: calc(100% + 4px);
          height: 52px;
          left: -2px;
          top: -2px;
          background: $body-main-gradient;
          border-radius: 14px;
          z-index: 1;
          opacity: 0;
          visibility: hidden;
          transition: .3s;
        }
      }

      &.v-input--is-focused {
        .v-input__slot {
          background: #fff;
        }

        .v-input__control {
          &:after {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
</style>
