<template lang="pug">
  v-col(cols="12" :class="classList")
    e-overlay-block(
      v-if="loading"
      :loading="loading"
      progress-width="6"
      progress-size="xxl"
      color="#161b25"
    )
    slot(v-if="!loading")

    m-info-block(v-if="'info' in slots && !loading" type='warning' class="mt-4")
      slot(name="info")
</template>

<script>
import { mapGetters } from 'vuex'
import MInfoBlock from '~/components/modules/blocks/m-info-block'
import EButtonHelp from '~/components/elements/buttons/e-button-help'
import responsive from '~/mixins/pages/responsive'
import EOverlayBlock from '~/components/elements/overlays/e-overlay-block'

export default {
  components: {
    EOverlayBlock,
    MInfoBlock,
    EButtonHelp
  },
  mixins: [responsive],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    supportButtons: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters([
      'profile'
    ]),
    classList () {
      return {
        't-orm-page': true,
        mobile: this.xsDevice
      }
    },
    slots () {
      return this.$slots
    }
  }
}
</script>

<style lang="scss">
  .t-orm-page {
    padding: 25px 20px;
    height: 100%;

    &.mobile {
      padding-top: 15px;
      margin-bottom: 55px;
    }

    @media (min-width: map-get($breakpoints, 'sm')) {
      padding: 25px;
    }

    &__payment-reminder {
      font-family: 'mariupol-regular', sans-serif;
      font-size: 0.69rem;
      color: #FF002B;
      border: 2px solid #FF002B;
      border-radius: 10px;
      font-weight: 700;
      padding: 5px;
      width: 100%;
      display: inline;
    }

    &__title {
      font-size: 1.25rem;
      font-weight: 500;

      @media (min-width: map-get($breakpoints, 'md')) {
        font-size: 1.75rem;
      }
    }
  }
</style>
