<template lang="pug">
  div(class="dialog")
    h3(
      v-if="!showSuccessRegistrationMessage"
      class="dialog__title"
    ) Створення облікового запису в системі партнера
    v-row(v-if="showSuccessRegistrationMessage")
      v-col(cols="12")
        div(class="text-center")
          h3(class="dialog__title mb-2") Обліковий запис успішно створено
          e-svg-icon(size="xbg") done-2
      v-col(cols="12")
        v-btn(
          class="main-button d-block mx-auto mb-3"
          @click="redirectToSystem(partnerLink)"
        ) Перейти у систему партнера
    v-row(v-else)
      v-col(cols="12" class="mb-6")
        h3 Тарифи:
        v-simple-table
          thead
            tr
              th
              th Товарів
              th Користувачів
              th Виробничих документів
              th(class="text-right") Ціна
          tbody
            tr(
              v-for="(item, index) in rows"
              :key="index"
            )
              td {{ item.name }}
              td {{ item.products }}
              td {{ item.users }}
              td {{ item.documents }}
              td(class="text-right") {{ item.price }}
      v-col(cols="12")
        h3(class="mb-2") Оберіть організації:
        m-orm-table(
          :model="orgModel"
          :buttons="tableButtons"
          ref="orgTable"
          selectable
        )
      v-col(cols="12")
        v-btn(
          class="main-button d-block mx-auto mb-3"
          @click="signInToPartnerSystem"
          :loading="loading"
        ) Вхід в систему партнера
</template>

<script>
import MOrmTable from '~/components/modules/tables/orm/m-orm-table'
import Organization from '~/models/directories/Organization'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Dialog from '~/models/system/Dialog'
import partners from '~/modules/partners/mixins/partners'

export default {
  name: 'BlockCreatePartnerSystemAccount',
  components: {
    ESvgIcon,
    MOrmTable
  },
  mixins: [partners],
  props: {
    providerKey: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: false,
    partnerLink: null,
    showSuccessRegistrationMessage: false
  }),
  computed: {
    orgModel () {
      return Organization
    },
    tableButtons () {
      return [
        {
          action: 'replace',
          items: [
            {
              visible: true,
              call: async () => {
                const dialog = Dialog.query().where('type', 'content_top').first()
                await dialog.open({
                  component: 'm-block-set-organization',
                  width: '500px'
                })
              }
            }
          ]
        }
      ]
    },
    rows () {
      return [
        { name: 'Стартап', products: 200, users: 5, documents: 10, price: 399 },
        { name: 'Стандарт', products: 5000, users: 10, documents: 30, price: 699 },
        { name: 'Бізнес', products: 10000, users: 30, documents: 50, price: 999 },
        { name: 'Преміум', products: 20000, users: 100, documents: 'Безлім', price: 1399 }
      ]
    }
  },
  methods: {
    async signInToPartnerSystem () {
      const selected = this.$refs.orgTable.selected
      if (selected.length) {
        try {
          this.loading = true
          const organizations = this._.map(selected, item => item.item)
          const statusData = await this.registerToPartnerSystem(this.providerKey, organizations)
          if (this._.get(statusData, 'status') === 'error') {
            this.$handlers.error(this._.get(statusData, 'errorMessage'), this)
          } else {
            this.partnerLink = this._.get(statusData, 'link')
            this.showSuccessRegistrationMessage = true
          }
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.loading = false
        }
      }
    },
    redirectToSystem (link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
