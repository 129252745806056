export const contexts = {
  default: 'default',
  create: 'create',
  read: 'read',
  update: 'update',
  delete: 'delete',
  // TODO: replace by first letter splitting and finding.
  'c.u': ['create', 'update', 'default'],
  'c.u.r': ['create', 'update', 'read'],
  'c.r': ['create', 'read'],
  r: ['read'],
  c: ['create'],
  u: ['update'],
  d: ['delete'],
  all () {
    return Object.values(this).filter(ctx => typeof ctx === 'string')
  },
  only (key) {
    const list = this.all()

    if (Array.isArray(key)) {
      return list.filter(ctx => key.includes(ctx))
    }

    if (key && key in this) {
      return list.filter(ctx => this[key].includes(ctx))
    }

    return list
  },
  isRead (v) {
    return this.r.includes(v)
  },
  isCreate (v) {
    return this.c.includes(v)
  },
  isUpdate (v) {
    return this.u.includes(v)
  },
  isDelete (v) {
    return this.d.includes(v)
  }
}

export const actions = {
  create: 'create',
  read: 'read',
  update: 'update',
  delete: 'delete'
}

export const ormConfig = {
  modelKey: 'entity'
}

export const formats = {
  date: 'DD.MM.YYYY',
  dateISO8601: 'YYYY-MM-DD',
  time: 'HH:mm:ss',
  dateTime: 'DD.MM.YYYY HH:mm:ss',
  dateTimeSec: 'YYYY-MM-DD HH:mm:ss',
  dateTimeWithoutSeconds: 'YYYY.MM.DD HH:mm',
  monthYear: 'MM.YYYY',
  dayMonth: 'DD.MM'
}
