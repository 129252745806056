import { Model } from '@vuex-orm/core'
import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import User from '~/models/administration/User'

export default class Billing extends ChainInheritance(Administration, [
  SoftDeletAble,
  TimestampAble,
  PersistAble
]) {
  static entity = 'billings'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'status'
  static defaultSortOrder = true

  static STATUS_TRIAL = 'trial';
  static STATUS_INVOICED = 'invoiced';
  static STATUS_IN_DEBT = 'in_debt';
  static STATUS_DISABLED = 'disabled';
  static STATUS_PAYED = 'payed';

  static fields () {
    return super.fields({
      user_id: this.attr(null),

      id: this.attr(null),
      payerCode: this.attr(null),
      units: this.attr(null),
      discount: this.attr(null),
      user: this.belongsTo(User, 'user_id'),
      status: this.attr(null)
    })
  }

  get userName () {
    return this._.get(this, 'user.profile.name', '')
  }

  get payerCodeString () {
    return '#' + this.payerCode
  }

  static ormFilters = [
    {
      model: 'status',
      component: 'v-select',
      provider: {
        name: 'Status',
        vid: 'status',
        rules: ''
      },
      attrs: {
        label: 'Status',
        outlined: true,
        clearable: true,
        'return-object': false
        // 'item-text': value => value.name
      },
      items: () => [Billing.STATUS_TRIAL, Billing.STATUS_PAYED, Billing.STATUS_INVOICED, Billing.STATUS_IN_DEBT, Billing.STATUS_DISABLED]
    },
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 6
              },
              fields: [
                'status'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 6
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormTrans = {
    single: 'Billing',
    multy: 'Billing'
  }

  static ormHeaders = [
    { text: 'Name', value: 'userName', sortable: false, filterable: false },
    { text: 'Status', value: 'status', sortable: false, filterable: false },
    { text: 'Payer code', value: 'payerCodeString', sortable: false, filterable: false },
    { text: 'Balance', value: 'units', sortable: false, filterable: false },
    { text: 'Discount', value: 'discount', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['status'],
      'sort-desc': [true]
    })
  }

  static ormActions = [
    {
      name: 'showPeriods',
      text: 'Show Periods',
      icon: {
        type: 'e-svg-icon',
        text: 'send'
      },
      call: (item, someField, ctx) => {
        ctx.$router.push('/admin/billing/' + item.id)
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.activate = function (id) {
        return this.put(Model.$routes.billings.activate(id))
      }

      return configActions
    }
  }
}
