<template lang="pug">
  v-app
    _drawer(v-if="$User && $User.isSuperAdmin")
    Header(v-else)
    v-content(:key="key")
      v-container(fill-height class="default pa-0 background align-start")
        billing-alert(
          v-if="showBillingAlert"
          :date-to-pay="dateToPay"
          :billing-status="billingStatus"
        )
        nuxt
    notification
    dialogs(ref="dialogs")
    e-new-version-informer
    sidebars
</template>

<script>
import drawer from './includes/_drawer'
import Header from './includes/_header'
import notification from './includes/_notification'
import dialogs from './includes/_dialogs'
import auth from '~/modules/auth/auth'
import authUser from '~/modules/auth/authUser'
import ENewVersionInformer from '~/components/elements/informers/e-new-version-informer'
import informationDialog from '~/mixins/dialogs/informationDialog'
import _drawer from '~/layouts/includes/_drawer'
import sidebars from '~/modules/sidebar/views/components/sidebars'
import billingAlert from '~/layouts/includes/_billingAlert'
import User from '~/models/administration/User'
import Billing from '~/models/billing/Billing'

export default {
  components: {
    _drawer,
    drawer,
    Header,
    notification,
    dialogs,
    ENewVersionInformer,
    sidebars,
    billingAlert
  },
  mixins: [informationDialog],
  data () {
    return {
      key: 1,
      dateToPay: null,
      billingStatus: null
    }
  },
  computed: {
    showDrawer () {
      return auth.isAuthenticated() && authUser.get()
    },
    showBillingAlert () {
      return !this.$User?.isSuperAdmin && (this.billingStatus === Billing.STATUS_INVOICED || this.billingStatus === Billing.STATUS_DISABLED)
    }
  },
  async created () {
    if (!this.$route.path.includes('/payments') && !this.$User?.isSuperAdmin && !this.$route.query?.afterRegistration) {
      await this.getBillingData()
    }
  },
  async mounted () {
    if (this.$Organization && !this._.get(this.$localStorageClientData, 'testModeNotificationViewed', false) && new Date(this.$Organization.dateCreated) < new Date('2021-12-27')) {
      await this.informationDialog.open({
        text: 'На порталі додано тестовий режим. Тепер у всіх користувачів з\'явилась можливість працювати з тестовою касою та касиром. Перейдіть у відповідні розділи на порталі, та виберіть відповідні данні з поміткою тест.',
        textPrefix: 'Увага, нова функція!',
        width: '550px',
        buttonText: 'Clear'
      })
      this.$setLocalStorageClientData({ testModeNotificationViewed: true })
    }
  },
  methods: {
    rerenderContent () {
      this.key = Date.now()
    },
    async getBillingData () {
      try {
        const billing = this._.get(await User.api().getUserBilling(), 'response.data')

        if (billing !== null) {
          this.billingStatus = billing.status
        }
        if (billing.datePayTo) {
          this.dateToPay = billing.datePayTo
        }
      } catch (e) {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  min-width: 320px !important;
}
</style>
