import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _28fa39da = () => interopDefault(import('../pages/payments/index.vue' /* webpackChunkName: "pages/payments/index" */))
const _4bb7511c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _342153c9 = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _44b60560 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _2429d884 = () => interopDefault(import('../pages/admin/billing/index.vue' /* webpackChunkName: "pages/admin/billing/index" */))
const _a884afc2 = () => interopDefault(import('../pages/admin/client-balances.vue' /* webpackChunkName: "pages/admin/client-balances" */))
const _04a7c427 = () => interopDefault(import('../pages/admin/client-integrations.vue' /* webpackChunkName: "pages/admin/client-integrations" */))
const _23d9978e = () => interopDefault(import('../pages/admin/Commissions.vue' /* webpackChunkName: "pages/admin/Commissions" */))
const _dc696e74 = () => interopDefault(import('../pages/admin/Organizations.vue' /* webpackChunkName: "pages/admin/Organizations" */))
const _2f5820ac = () => interopDefault(import('../pages/admin/Payment.vue' /* webpackChunkName: "pages/admin/Payment" */))
const _52f9eb78 = () => interopDefault(import('../pages/admin/Periods.vue' /* webpackChunkName: "pages/admin/Periods" */))
const _5d8d172e = () => interopDefault(import('../pages/admin/Users.vue' /* webpackChunkName: "pages/admin/Users" */))
const _43fe2188 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _23d2ff46 = () => interopDefault(import('../pages/auth/registration.vue' /* webpackChunkName: "pages/auth/registration" */))
const _bf739692 = () => interopDefault(import('../pages/dashboard/api-key.vue' /* webpackChunkName: "pages/dashboard/api-key" */))
const _233208bf = () => interopDefault(import('../pages/dashboard/MyOrganizations.vue' /* webpackChunkName: "pages/dashboard/MyOrganizations" */))
const _3cc9e476 = () => interopDefault(import('../pages/payment/error.vue' /* webpackChunkName: "pages/payment/error" */))
const _1f9f5100 = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _499b116f = () => interopDefault(import('../pages/admin/billing/_id/index.vue' /* webpackChunkName: "pages/admin/billing/_id/index" */))
const _23f84489 = () => interopDefault(import('../pages/auth/set-password/_token/index.vue' /* webpackChunkName: "pages/auth/set-password/_token/index" */))
const _c8ecd7e6 = () => interopDefault(import('../pages/redirect/_partnerName.vue' /* webpackChunkName: "pages/redirect/_partnerName" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard"
  }, {
    path: "/payments",
    component: _28fa39da,
    name: "payments"
  }, {
    path: "/profile",
    component: _4bb7511c,
    name: "profile"
  }, {
    path: "/redirect",
    component: _342153c9,
    name: "redirect"
  }, {
    path: "/terms",
    component: _44b60560,
    name: "terms"
  }, {
    path: "/admin/billing",
    component: _2429d884,
    name: "admin-billing"
  }, {
    path: "/admin/client-balances",
    component: _a884afc2,
    name: "admin-client-balances"
  }, {
    path: "/admin/client-integrations",
    component: _04a7c427,
    name: "admin-client-integrations"
  }, {
    path: "/admin/Commissions",
    component: _23d9978e,
    name: "admin-Commissions"
  }, {
    path: "/admin/Organizations",
    component: _dc696e74,
    name: "admin-Organizations"
  }, {
    path: "/admin/Payment",
    component: _2f5820ac,
    name: "admin-Payment"
  }, {
    path: "/admin/Periods",
    component: _52f9eb78,
    name: "admin-Periods"
  }, {
    path: "/admin/Users",
    component: _5d8d172e,
    name: "admin-Users"
  }, {
    path: "/auth/forgot-password",
    component: _43fe2188,
    name: "auth-forgot-password"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login"
  }, {
    path: "/auth/registration",
    component: _23d2ff46,
    name: "auth-registration"
  }, {
    path: "/dashboard/api-key",
    component: _bf739692,
    name: "dashboard-api-key"
  }, {
    path: "/dashboard/MyOrganizations",
    component: _233208bf,
    name: "dashboard-MyOrganizations"
  }, {
    path: "/payment/error",
    component: _3cc9e476,
    name: "payment-error"
  }, {
    path: "/payment/success",
    component: _1f9f5100,
    name: "payment-success"
  }, {
    path: "/admin/billing/:id",
    component: _499b116f,
    name: "admin-billing-id"
  }, {
    path: "/auth/set-password/:token?",
    component: _23f84489,
    name: "auth-set-password-token"
  }, {
    path: "/redirect/:partnerName",
    component: _c8ecd7e6,
    name: "redirect-partnerName"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
