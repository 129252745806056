import Pivot from '~/models/abstracts/system/Pivot'

export default class OrganizationIntegration extends Pivot {
  static entity = 'OrganizationIntegration'

  static integrations = {
    dn_trade: {
      name: 'navkolo.trading',
      icon: 'building-24.svg'
    },
    book_keeper: {
      name: 'navkolo.accounting basic',
      icon: 'yellow-book.svg'
    },
    huge_profit: {
      name: 'navkolo.CRM',
      icon: 'settings-4.svg'
    },
    master_buh: {
      name: 'navkolo.accounting pro',
      icon: 'receipt.svg'
    }
  }

  static getIconUrl (icon) {
    return require('~/assets/sprite/svg/' + icon)
  }

  static fields () {
    return {
      organizationId: this.attr(null),
      id: this.attr(null),
      status: this.attr(null),
      userIntegration: this.attr(null)
    }
  }
}
