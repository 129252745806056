import authUser from '~/modules/auth/authUser'
import Organization from '~/models/directories/Organization'

export default async function ({ route }, force = false, silent = true) {
  const excludedRoutes = ['index', 'auth-login', 'payment-success', 'payment-error']
  if (route && excludedRoutes.includes(route.name)) {
    return
  }
  if (authUser.get() && !authUser.get().isSuperAdmin) {
    const reloadOrganizations = true
    if (force || reloadOrganizations) {
      try {
        await Organization.api().within(authUser.get(), '/organizations')
      } catch (e) {
        if (!silent) { throw e }
      }
    }
  }
}
